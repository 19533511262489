var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c("lang", { staticClass: "lang" }),
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form fadeIn",
          attrs: {
            model: _vm.loginForm,
            rules: _vm.loginRules,
            "auto-complete": "off",
            "label-position": "left",
          },
        },
        [
          _c("div", { staticClass: "title-container" }, [
            _c("h3", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("login.title"))),
            ]),
          ]),
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                1
              ),
              _c("el-input", {
                ref: "username",
                attrs: {
                  placeholder: "Username",
                  name: "username",
                  "auto-complete": "off",
                  type: "text",
                  tabindex: "1",
                },
                model: {
                  value: _vm.loginForm.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "username", $$v)
                  },
                  expression: "loginForm.username",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                1
              ),
              _c("el-input", {
                key: _vm.passwordType,
                ref: "password",
                attrs: {
                  type: _vm.passwordType,
                  placeholder: "Password",
                  name: "password",
                  "auto-complete": "off",
                  tabindex: "2",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleLogin()
                  },
                },
                model: {
                  value: _vm.loginForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "password", $$v)
                  },
                  expression: "loginForm.password",
                },
              }),
              _c(
                "span",
                { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                [
                  _c("svg-icon", {
                    attrs: {
                      "icon-class":
                        _vm.passwordType === "password" ? "eye" : "eye-open",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: {
                width: "100%",
                "margin-bottom": "10px",
                height: "35px",
                "font-size": "14px !important",
              },
              attrs: { loading: _vm.loading, type: "success" },
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleLogin(1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("login.login")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "warning",
              staticStyle: {
                width: "100%",
                "margin-bottom": "10px",
                height: "35px",
                "font-size": "14px !important",
              },
              attrs: { loading: _vm.demoLoading },
              on: {
                click: function ($event) {
                  return _vm.handleLogin(2)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("login.demo")))]
          ),
          _c("div", { staticClass: "tips" }, [
            _c(
              "span",
              {
                staticStyle: { "margin-right": "20px" },
                on: { click: _vm.handleRegister },
              },
              [_vm._v(_vm._s(_vm.$t("login.register")))]
            ),
            _c("span", { on: { click: _vm.forgetPassword } }, [
              _vm._v(_vm._s(_vm.$t("login.Forgot"))),
            ]),
          ]),
          _c("div", { staticClass: "scodw" }, [
            _c("div", [_c("p", [_vm._v(_vm._s(_vm.$t("login.text")))])]),
            _c(
              "div",
              {
                staticStyle: { display: "inline-block", cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.openUrl(_vm.appUrl)
                  },
                },
              },
              [
                _c("vue-qr", {
                  attrs: {
                    logoSrc: _vm.logoImg,
                    text: _vm.appUrl,
                    size: 83,
                    margin: 3,
                  },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(_vm._s(_vm.$t("login.appUrl"))),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: { display: "inline-block", cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.openUrl(_vm.googleAppUrl)
                  },
                },
              },
              [
                _c("vue-qr", {
                  attrs: {
                    logoSrc: _vm.logoImg,
                    text: _vm.googleAppUrl,
                    size: 83,
                    margin: 3,
                  },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(_vm._s(_vm.$t("login.googleAppUrl"))),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: { display: "inline-block", cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.openUrl(_vm.appleAppUrl)
                  },
                },
              },
              [
                _c("vue-qr", {
                  attrs: {
                    logoSrc: _vm.logoImg,
                    text: _vm.appleAppUrl,
                    size: 83,
                    margin: 3,
                  },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(_vm._s(_vm.$t("login.appleAppUrl"))),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                padding: "2px",
                margin: "15px 0 5px 0",
                border: "1px solid black",
                "text-align": "center",
              },
            },
            [_c("label", [_vm._v(_vm._s(_vm.$t("login.technicalSupport")))])]
          ),
          _c("div", { staticClass: "scodw" }, [
            _c(
              "div",
              { staticStyle: { display: "inline-block", cursor: "pointer" } },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/login/whatupqr.jpg"),
                    width: "103",
                  },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(_vm._s(_vm.$t("login.whatsApp"))),
                ]),
              ]
            ),
            _c(
              "div",
              { staticStyle: { display: "inline-block", cursor: "pointer" } },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/login/wechartqr.jpg"),
                    width: "103",
                  },
                }),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(_vm._s(_vm.$t("login.wechat"))),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "tips" },
            [
              _c(
                "el-link",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    href: "/doc/Monitoring_platform_usage_instructions.pdf",
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("login.monitoringPlatformUsageInstructions")
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "min-width": "400px" },
          attrs: { title: _vm.dialog.title, visible: _vm.dialog.visible },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialog, "visible", $event)
            },
          },
        },
        [
          _c(_vm.componentName, {
            tag: "components",
            attrs: { props: _vm.dialog },
          }),
        ],
        1
      ),
      _c("canvas", { attrs: { id: "background" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }