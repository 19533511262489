/*
 * @Author: sun.mengmeng
 * @Date: 2021-07-21 10:01:02
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-09-08 15:31:06
 * @Description: file content
 */
import axios from "axios";
import {
  MessageBox,
  Message
} from "element-ui";
import store from "@/store";
import i18n from "@/lang";
import * as util from "@/utils/index";
const TokenKey = "plant-token";
import router from "@/router";
let apiBaseUrl = process.env.VUE_APP_BASE_API;
console.log("apiBaseUrl= ", apiBaseUrl)
const lang=util.readSession("lang")
const service = axios.create({
  baseURL: apiBaseUrl,
  headers: {
   "Content-Type": "application/json;charset=UTF-8",
   'Accept-Language':lang
  },
  timeout: 1800000 // request timeout
});

service.interceptors.request.use(
  config => {
    const token = util.readSession(TokenKey);
    if (token) {
     config.headers["Authorization"] = util.readSession(TokenKey);
     config.headers["Accept-Language"] = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
let index = 0;
service.interceptors.response.use(
  response => {
    const {
      status,
      data,
      config
    } = response;
    const {
      code,
      msg
    } = data;
    if (code !== 200) {
      switch (code) {
        case 401:
          index += 1;
          if (index <= 2) {
            errorMsg(i18n.t("request.VerificationFailedPleaseLogInAgain"));
          }
          store.dispatch("user/resetToken");
          router.push({
            path: `/Login`
          });
          break;
        case 600:
          return data;
        case 500:
          errorMsg(msg || i18n.t("request.NetworkExceptionPleaseTryAgainLater"));
          break;
        default:
          let s = i18n.t("request.TheBackEndInterface")+`${code}`+i18n.t("request.Abnormal")
          errorMsg(msg || s);
          break;
      };
      Promise.reject(
        i18n.t("request.RequestExceptionInterception")+":" + JSON.stringify({
          url: config.url,
          code,
          msg
        })
      );
      return data;
    } else {
      return data;
    }
  },
  error => {
    const {
      status,
      data
    } = error.response
    if (status !== 200) {
      errorMsg(data.msg || i18n.t("request.TheBackEndInterfaceIsAbnormal"));
    }
    return Promise.reject(error);
  }
);

const errorMsg = message => {
  return Message({
    message: message,
    type: "error",
    duration: 8000
  });
};


export default service;
