/*
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-14 18:14:26
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-24 15:32:43
 */
export default {

  common: {
    warning: 'warning',
    error: 'error',
    hint: 'hint',
    input: 'input',
    picUrl: 'https://api1.gnetek.com/stationPhotos/'
  },

 login: {
  title: 'Welcome to GNE photovoltaic monitoring system',
  login: 'Sign in',
  demo: 'Demo',
  register: 'Register',
  Forgot: 'Forgot password',
  // text: 'Welcome to GNE web version PV monitoring system!Please scan the QR code on the left to install the Android APP and check the real-time status of your power station through your mobile phone.',
  text: 'You can search for "gne" directly on Google play or Apple store, or download the mobile client from the link below:',
  appUrl:'Download Apk',
  googleAppUrl:'Google play',
  appleAppUrl:'Apple store',
  sure: 'Are you sure you want to exit the monitoring platform?',
  person: 'Personal center',
  station: "Station List",
  out: 'Log out',
  tip: 'Prompt',
  confirm: 'Confirm',
  cancel: 'Cancel',
  beSure: 'Do you want to delete it?',
  china: 'Chinese',
  english: 'English',
  janpan: 'Japanese',
  sucess:'Multiple languages switch successfully',
  switchLang:'Languages',
  technicalSupport:'Technical Support',
  wechat:'WeChat',
  whatsApp:'WhatsApp',
  monitoringPlatformUsageInstructions: 'Monitoring platform usage instructions'
 },
 register: {
  title: 'Jiangsu GNE New Energy Technology Co., Ltd.',
  registerAccount: 'Registered account',
  userName: 'User Name',
  email: 'Email',
  ConfirmEmail: 'Confirm Email',
  password: 'Password',
  ConfirmPassword: 'Confirm Password',
  company: 'Company',
  postcode: 'PostCode',
  surname: 'Surname',
  timezone: 'TimeZone',
  Verification: 'Verification Code',
  street:'Street',
  regain: 'regain',
  back: 'Back',
  register:'Done',
  setName: 'Setting a User Name',
  pSetName: 'Please set the user name',
  setEmail: 'Please input email address',
  pSetEmail: 'Please enter your registered email address',
  cSetEmail: 'Confirm email again',
  PCsetEmail:'Please confirm the registered email address',
  setPassword: 'Reset new password',
  PSetPassword: 'Please set the login password',
  CPasword: 'Confirm Password',
  PcPassword: 'Please confirm the login password',
  PCompany: 'Please enter company name',
  PEnter: 'Please input',
  PSelect: 'Please select',
  PenterCode: 'Please enter the verification code',
  setCode: 'Send verification code' ,
  resert: 'Reset the success',
  chanName: 'Name',
  naromlRole: 'Role',
  Prole: 'Please select roles',
  sucess: 'Operate successfully',
  pCheckout: 'Please select a data first',
  resSucess:'Registered successfully',
  max16:'The length cannot exceed 16 characters',
  moveVerify:"Please move the slider to verify",
  getCaptcha:'Get code',
  sentCaptcha2Email:'The verification code has been sent to the email',
  sendCaptchaAgain:'s, resend',
  usernameIsExist:'The username already exists',
  emailIsExist:'The email already exists',
 },
 //首页
 dashbord: {
  dateAmount: 'Daily electricity diagram',
  monthAmount: 'Monthly Power View',
  weekAmount:'Weekly Power View',
  yearAmount: 'Annual Power View',
  Charge: 'Compare Chart',
  Power: 'Power Diagram',
  month: 'month',
  quarter:'quarter',
  year: 'year',
  Daily: 'Daily Energy',
  Total: 'Total Income',
  NumberS: 'Modules',
  capacity: 'Installed Capacity',
  gross: 'Total Energy',
  health: 'Health',
  weather: 'weather',
  Device: 'Device name',
  Alarm: 'Alarm content',
  time: 'time',
  serial:'The serial number',
  max3Diff:'A maximum of three years can be selected for comparison',
  currentPower: 'Current power'
 },
  //曲线
  curve: {
   choiceDate: 'Select a date',
   power: 'Power',
   electricity: 'Energy',
   Pfliter: 'Enter the keyword for filtering',
    PSelect: 'You can select no more than 7',
    Pset:'Select the honeybee  first'
 },
   // 设备报警
 device: {
  to: 'to',
  startTime: 'Start date',
  endTime: 'End date',
  search: 'search',
  Unhandled: 'Warning History',
  Historical: 'Untreated Warning',
  name: 'Designation',
  place: 'Place',
  time: 'Time',
  type:'Type',
  deadline:'By the end of time',
  detail:'Details'

 },
  //电站管理-电站列表
  stationReport: {
   stationList: 'Station List',
   addList: 'Pending Systems',
   country: 'country',
   city:'city',
   district:'region',
   stationName:'station name',
   addStation:'Add Station',
   search:'Search',
   reset: 'reset',

   Verification:'Verification code authentication',
   Penter:'please input',
   code:'Verification code',
   regain:'regain',
   cancel:'Cancel',
   confirm:'Submit',
   PVerification:'Please Enter Verify Code ',
   OperationSuccess:'operate successfully',
   errorCode:'The verification code is incorrect. Please re-enter it',
   state:'Status',
   name:'Site Name',
   address:'Address',
   cumulative: 'Total Energy',
   peakPower:'Installation Capacity',
   installation:'Installed Date',
   operation:'Management',
   open:'Power On',
   down:'Power Off',
   openStation:'Power on optimizer/RSD',
   closeStation:'Power off optimizer/RSD',
   Switch: 'Change to the selected station?',
   Continue: 'Continue installation ',
   delete: 'Delete',
   isDelete:'Are you sure to delete',

    SearchPowerStation: 'Search power station',
    PowerSwitch: 'Power switch',
    HasBeenInstalled: 'Has been installed',
    NotInstalled: 'Not installed',

  },
   //电站控制
 control: {
  pleaseSelectStation: 'Please select Station',
  MainSwitch: 'Total switch',
  SeparateSwitch: 'Unit switch',
  close: 'Power Off',
  open:'Power On',
  closeStation: 'Power off optimizer/RSD',
  openStation: 'Power on optimizer/RSD',
  ptcodeR: 'Verification code authentication',
  PEnter: 'Please enter',
  ptcode: 'Verification code',
  angin: 'regain',
  cancel:'Cancel',
  confirm:'Confirm',
  notiClosed: 'ATTENTION: The station will stop powering when click the above button！',
  notiOpen:'ATTENTION: The station will start powering when click the above button！',
  SummaryId: 'Beehive MACID',
  PositionCode: 'Location ',
  status: 'Status',
  updateTime: 'Update Time',
  selectAll: 'select all',
  PenterCode: 'Please enter the verification code',
  sucess: 'Operation is successful',
  pCheckout:'Please select a data item first',

 },
  //设备状态
  status: {
   SummaryId: 'Beehive MACID',
   version: 'Version Number',
   updateTime: 'Update Time',
   status: 'Status',
   PositionCode: 'Location ',
   voltage: 'Voltage(V)',
   current: 'Current(A)',
   power:'Power(W)',
   warnCode: 'Status',

 },
   // 角色管理
 roleManagement: {
  creatRole: 'Add Role',
  detete:'Delete',
  Penter: 'Enter a role name',
  roleName: 'Role',
  roleNotice: 'Description',
  action: 'Operation',
  edit: 'Edit',
  cancel:'Cancel',
  submit: 'Submit',
  addRole: 'Add Roles',
  editRole: 'Editor role',
  selectAll: 'select all',
  PSelect: 'Select delete items first',
  Resource:'Resource allocation',
   TheUploadedFileFormatIsIncorrect:'The uploaded file format is incorrect',


 },
  // 用户管理
  userManagement: {
   creatUser: 'Add User',
   detete:'Detete',
   Penter: 'Please enter a user name',
   userName: 'User Name',
   userStatus: 'User state',
   action: 'Operation',
   edit: 'Update',
   cancel:'Cancel',
   submit: 'submit',
   addUser: 'New users',
   editUser: 'Edit the user',
   selectAll: 'select all',
   PSelect: 'Select delete items first',
   Email:'Email',
    reset:'Reset Password',
 ModifySuccessfully:'Modify Successfully',

 },
   //个人中心
 PersonalMsg: {
  userName: 'User Name',
  postcode: 'Phone',
  Certified: 'Email',
  reset:'Reset Password',
  save: 'Save',
  initalPasw: 'Password',
  Pinit: 'Please enter the Original Password',
  NewPasw: 'New Password',
  PnewPasw: 'Please enter a New Password',
  CPasw: 'Confirm Password ',
  PCword: 'Please enter the New Password again',
  cancel:'Cancel',
  confirm: 'Confirm',


 },

//电站设置
config: {
 plant: "Station Information",
 Inverter: "Inverter & Module",
 Summary: "Data Devices",
 setOptimizer: "Optimizer/RSD",
 Layout: "Layout",
 PnewPower: "Please create a new power station",
 Pcomplete: "Please complete the procedure",

 manufacturer: "Manufacturer:",
 modelType: "Model:",
 comPower: "Module Power(W):",
 Tags: "Label:",
 InverterMan: "Manufacturer:",
 RatedPower: "Peak Power(kW):",



 SummarySet: "Beehive Setting",
 UnitId: 'Please enter Mac ID',
 PCode: 'The value contains more than 8 characters',
 notExist: "The Mac ID does not exist",
 save: 'Save',
 undo: 'Undo',
 redo: 'Redo',
 PenterAgin: "The Mac ID is invalid. Please re-enter it",
 UnitEror: "The Mac ID is incorrect. Please check whether the Mac ID is correct",

 Pselect: "Please select an input type",
 PMAc: 'Enter a MAC ID',
 MacNotExist: "The MAC Id does not exist",
 Components: "Module",
 PComponents: 'Select Module',
 delete: 'Delete',
 insert: 'Insert',
 Single: "Honeybee350/400/650/700/850",
 Double: "Honeybee800/1300/1600",


 Method1: "Mode A",
 Method2: "Mode B",
 string: "String-",
  Inprocessing:"In processing",

 inverterName: "Inverter-",
 moduleName: "Module-",


 Xcoordinate: "X coordinate",
 Ycoordinate: "Y coordinate",
 success: "New success",



 stationName: "Station Name:",
 Countries: "Country:",
 ProjectNo: "System Code:",
 Provinces: "Province:",
 Company: "Company:",
 City: "City:",
 chargePerson: "Head of Station:",
 Area: "Region:",
 Telephone: "Telephone:",
 Street: "Street:",
 Time: "TimeZone:",
 PselectEnter: "please select",
 Price: "Electricity Price:",
 PriceCode: "Please select the currency",
 pictures: "Upload Image:",
 Upload: 'Click to upload',
 tips: 'Please upload an image format file, the size of the image cannot exceed 1MB',
 tipsOverSize:'Uploaded images cannot exceed 1MB!',
 tipsWrongFileTpye:'Uploaded images can only be in JPG, JPEG, PNG format!',
 tipsMaximumUploads:'Maximum Upload up to 1 image!',

 excelLabel:"Uploading excel Files:",
 tipsExcelUpload1:"Drag the file here，or ",
 tipsExcelUpload2:"Click Upload",
 tipsExcel:"Only excel(.xlsx) files can be uploaded,and no more than 10MB",
 tipsExcelOverSize:"The size of the uploaded file cannot exceed 10MB!",
 tipsExcelWrongFileTpye:'Only excel(.xlsx) files can be uploaded!',
 tipsExcelMaximumUploads:'Upload one file at most!',


 beSave: "Whether to save current content!",
 confirm: 'Confirm',
 cancel: "Cancel",
 NewOptimizer: "Add honeybee",


 beDeleteInverterOrMoudel: "You deleted the inverters or moudels. Are you sure you want to save the delete?",
 beDeleteBeehive: "Are you sure you want to delete the Beehive?",

 MacError: 'The Mac address is incorrect. Check whether the Mac address is correct',
 saveSucess: 'Save success',
 Pwrite: 'Please complete',
 beSaveString: "Whether to save the current string!",
 beDetelString: "Delete current string!",
 paswordRule: "Passwords should be 6-12 digits, letters or characters!",
 resetMacId: "The macId is invalid or already in use. Please re-enter",
 beDetelIntervl: "Delete current honeybee!",
 duplicateMacId: "Duplicate MACID",
 saveToNext: "Whether the honeybee setup is complete needs to go to the next step?",

  EditOptimizerSpecification: "Edit honeybee excel specification:",
  EditOptimizerTemplate: "Edit honeybee excel template:",
  download:"Download",

  doDeleteUserStations: "Are you sure you want to delete user-owned power stations?",

  PMacIdImage:"Enter the Mac ID based on the image",
  clickMe:"Click me",
  inverterTips:"Only one Inverter of the same model needs to be filled",
  moduleTips:"Only one Module of the same model needs to be filled",
  testText: "Test text",
  powergrid: "Power grid",
  compass: "Compass",
  swarm: "Swarm",
  errorHoneeybeeMacId:"These MAC IDs is incorrect or are already in use at another station.",
  honeybeeNumOverMaximum:"The number of optimizers grouped exceeds 25. Are you sure you want to save them?",
},
//布局设计
design: {
 voltage: 'Voltage(V)',
 current: 'Current(A)',
 power:'Power(W)',
 temperature: 'Temperature',
 warnCode: 'Status',
 PSelect: 'please select',
 physical:"Physical",
 logic:"Logic",

  power_simple: 'Power',
  voltage_simple: 'Voltage',
  current_simple: 'Current',
  temperature_simple: 'Temperature',
  start: 'Start',
  stop: 'Stop',

  defaultColor: 'Default',
  themeColor: 'Theme',
  standardColor: 'Standard',
  moreColors: 'More...',

  selectDate: 'Please select the date'
 },
 addRules: {
  Powassd: 'Please enter your password',
  nextCode: 'The password contains no more than 16 characters',
  nextNum: 'The password is a combination of letters, digits, and characters',
  PowassdAgen: 'Please enter your password again',
  NoSure: 'The two input passwords are inconsistent!',
  NoEmpty: 'The content cannot be empty',
  NumSet: 'The value contains a maximum of 64 characters',
  NumSix: 'The content cannot exceed 16 characters',
  // BeNum: '内容必须为数字值',
  Behuande: 'Content must be an integer value from 0 to 100',
  Bephone: 'Please fill in the correct cell phone number',
  BeEmail: 'Please fill in the correct email address',
  BeSure: 'Please enter the correct content',
  Numthree: 'Contains a maximum of 32 characters',
  setInput: 'Please input',
  Prole: 'Please select a role type',
  entSix:'The password contains at least 6 characters',
  setLogo: 'The modification succeeded. Please log in again!',
  setCodeNum: 'A string of 6 to 16 letters, numbers, or symbols',
   EmailFormat: 'Please enter the correct email format',
   Max8Char: 'The content cannot exceed 8 characters',
   Max32Char: 'The content cannot exceed 32 characters',
   Max64Char: 'The content cannot exceed 64 characters',
   PleaseEnterYourEmailAddressFirst:'Please enter your email address first',
 },
 otherMain: {
  uplod:'In processing',
   JustNow:'Just now',
   MinutesAgo:'Minutes ago',
    HoursBefore:'Hours before',
   OneDayBefore:'One day before',
   Month:'Month',
   Day:'Day',
   Hour:'Hour',
   Minute:'Minute',
 },
  request:{
    VerificationFailedPleaseLogInAgain:'Verification failed, Please log in again',
    TheInputContentCannotBeEmpty:'The input content cannot be empty',
    PleaseEnterYourEmailAddress:'Please enter your email address',
    NetworkExceptionPleaseTryAgainLater:'Network exception, please try again later',
    RequestExceptionInterception:'Request exception interception',
    TheBackEndInterfaceIsAbnormal:'The back end interface is abnormal',
  },
  validate: {
    TheInputContentCannotBeEmpty:'The input content cannot be empty',
    PleaseEnterThePlantName:'Please enter the plant name',
    PleaseEnterCountry:'Please enter country',
    PleaseEnterRegion:'Please enter region',
    PLeaseEnterProvince:'Please enter province',
    PleaseEnterTheCity:'Please enter the city',
    PleaseEnterStreet:'Please enter street',
    PleaseEnterANumber:'Please enter a number',
    PleaseEnterA5Number:'Please enter a number greater than 0',
    PleaseEnterManufacturer:'Please enter module manufacturer',
    PleaseEnter200to1000Number:'Module power should range from 200 to 1000',

  },
  rules:{
    TheContentCannotBeEmpty:'The content cannot be empty',
    TheValueContainsAMaximunOf64Characters:'The value contains a maximun of64 characters',
    TheContentCannotExceed20Characters:'The content cannot exceed 20 characters',
    TheContentMustBeNumeric:'The content must be numeric',
    TheContentMustBeLettersAndNumbers:'The content must be letters and numbers',
    ContentMustBeAnIntegerValueFrom0To100:'Content must be an integer value from 0 to 100',
    PleaseFillInTheCorrectCellPhoneNumber:'Please fill in the correct cell phone number',
    PleaseFillInTheCorrectEmailAddress:'Please fill in the correct email address',
    ContainsMoreThan64Characters:'Contains more than 64 characters',
    PleaseEnterTheCorrectFormat:'Please enter the correct format',
    TheValueContainsMoreThan32Characters:'The value contains more than 32 characters',
    PleaseEnterContent:'Please enter content',
    PleaseSelectCompany:'Please select company',
    PleaseSelectARoleType:'Please select a role type',
    TheIdCanOnlyBeANumber:'The ID can only be a number',
    PleaseEnterTheLabelName:'Please enter the label name',
    ContainsMoreThan16Characters:'Contains more than 16 characters',
    PleaseSelectA:'Please select a',
    PleaseEnterAModelName:'Please enter a model name',
    PleaseEnterTheVersionNumber:'Please enter the version number',
    PleaseEnterTheModelDescription:'Please enter the model description',
    PleaseSelectTheTrainingEnvironment:'Please select the training environment',
    PleaseEnterTheAlgorithmPath:'Please enter the algorithm path',
    PleaseSelectTheTrainingSet:'Please select the training set',
    PleaseSelectReviewers:'Please select reviewers',
    PleaseSelectAPlan:'Please select a plan',
    PleaseInputYourReviewComments:'Please input your review comments',
    PleaseEnterASchemaName:'Please enter a schema name',
    ThePasswordIsACombinationOfLettersDigitsAndCharacters:'The password is a combination of letters、digits and characters',
    PleaseEnterTheCorrectName:'Please enter the correct name',
    PleaseEnterCorrectInstructions:'Please enter correct instructions',
    EnterTheCorrectPath:'Enter the correct path',
    TheValueContainsMoreThan128Characters:'The value contains more than 128 characters',
    PleaseEnterTheCorrectContent:'Please enter the correct content',
    PleaseSelectAtLeastOne:'Please select at least one',
    TheValueContainsAMaximumOf256Characters:'The value contains a maximum of 256 characters',

  }
}
