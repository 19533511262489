<!--
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-11-02 23:46:05
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-07 21:08:44
-->
<template>
  <div class="station">
    <GnGrid
      ref="grid"
      :url="getStationListsAPI"
      :params="gridOptions"
      :methods="'get'"
      @row-click="handleClick"
      style="width:100%"
      :render-content="gridRenderContent"
      class="report-grid"
    />
    <el-dialog :title="dialog.title" :visible.sync="dialog.visible" width="30%">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="box_from">
        <el-form-item :label="$t('stationReport.Verification')" prop="resertCode">
          <el-input
            v-model="ruleForm.resertCode"
            :placeholder="$t('stationReport.Penter')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('stationReport.code')">
          <el-input v-model="imgSrc" disabled>
            <template slot="append">
              <el-button @click="getVerify">{{$t('stationReport.regain')}}</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item class="form-btn">
          <el-button @click="dialog.visible = false" type="primary" round
            >{{$t('stationReport.cancel')}}</el-button
          >
          <el-button @click="submitForm('ruleForm')" type="success" round
            >{{$t('stationReport.confirm')}}</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Confirm from "@/mixins/Confirm";
import store from "@/store";
import rowCloum from "@/mixins/rowCloum";
import { switchStation } from "@/api/user";
import { captcha, verify, cmdControl } from "@/api/register";
import * as util from "@/utils";
import { stationRemove } from "@/api/station";
/**
 * @author: JIXUYU
 * @since: 2021-11-02
 * @router('/station')
 */
export default {
  name: "station",
  components: {},
  mixins: [Confirm, rowCloum],
  props: {
    gridOptions: {
      type: Object,
      default() {
        return {
          installed: true
        };
      }
    }
  },
  data() {
    return {
      getStationListsAPI: "/station/list",
      ruleForm: {
        resertCode: ""
      },
      dialog: {
        title: "",
        visible: false
      },
      idList: "", // 电站id
      status: "",
      imgSrc: "",
      rules: {
        resertCode: [
          {
            required: true,
            message: this.message,
            trigger: "blur"
          },
          {
            validator: this.validateCode,
            trigger: ["blur"]
          }
        ]
      }
    };
  },
  watch: {
    "dialog.visible": {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.$refs["ruleForm"] && this.$refs["ruleForm"].resetFields();
        }
      }
    }
  },
  computed:{
     message(){
      return this.$t('stationReport.PVerification')
    }
  },
  methods: {
    /**
     * @description: 表单内容提交
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */

    submitForm(formName) {
      let _this = this;
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const params = {
            stationId: _this.idList,
            ids:'FFFFFFFF',
            status: this.status == "open" ? "0" : "1"
          };
          await cmdControl(params).then(res => {
            if (res.code === 200) {
              _this.$message.success(this.$t('stationReport.OperationSuccess'));
              this.dialog.visible = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    /**
     * @description: 校验验证码
     * @param {*} rule
     * @param {*} value
     * @param {*} callback
     * @return {*}
     * @author: JIXUYU
     */
    async validateCode(rule, value, callback) {
      const { code, data } = await verify({
        code: this.ruleForm.resertCode,
        businessCode: "stationControl"
      });
      if (code != 200) {
        callback(this.$t('stationReport.errorCode'));
      } else {
        callback();
      }
    },
    /**
     * @description: 电站列表数据渲染
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    gridRenderContent() {
      return [
        {
          label: this.$t('stationReport.state'),
          render: scope => (
            <span class={scope.row.onStatus === 0 ? "status" : "statusActive"}>
              {scope.row.onStatusName}
            </span>
          )
        },
        {
          label: this.$t('stationReport.name'),
          render: scope => <span>{this.MixinsClounm(scope.row.name)}</span>
        },
        {
           label: this.$t('stationReport.address'),
          render: scope => (
            <span>
              {
                localStorage.getItem('lang')==='zh'?
                util.defaultValue(scope.row.country) +" "+
                util.defaultValue(scope.row.province) +" "+
                util.defaultValue(scope.row.city) +" "+
                util.defaultValue(scope.row.district)
                :
                util.defaultValue(scope.row.district)+" "+
                util.defaultValue(scope.row.city) +" "+
                util.defaultValue(scope.row.province) +" "+
                util.defaultValue(scope.row.country)
              }
            </span>
          )
        },
         {
          label: this.$t('stationReport.peakPower'),
          render: scope => <span>{this.MixinsClounm(scope.row.peakpower)}kW</span>
        },
        {
          label: this.$t('stationReport.cumulative'),
          render: scope => <span>{this.MixinsClounm(scope.row.sumenergy)}kW/h</span>
        },

        {
          label: this.$t('stationReport.installation'),
          render: scope => <span>{this.MixinsClounm(scope.row.createdon)}</span>
        },
        {
          label: this.$t('stationReport.operation'),
          // width: 70,
          render: scope => (
            <div>
              <el-button style="margin:2px 0;"
                type="success"
                onClick={(event) => this.handleOpen(event, scope.row, "open")}
              >
                {this.$t('stationReport.open')}
              </el-button><br/>
              <el-button style="margin:2px 0;"
                type="danger"
                onClick={(event) => this.handleOpen(event, scope.row, "closed")}
              >
                {this.$t('stationReport.down')}
              </el-button>
            </div>
          )
        },
        {
          label: this.$t('stationReport.operation'),
          render: scope => (
            <div>
              <el-button
                type="danger"
                onClick={(event) => this.handleDel(event, scope.row)}
              >
                {this.$t("stationReport.delete")}
              </el-button>
            </div>
          )
        }
      ];
    },
    /**
     * @description:开启操作
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    handleOpen(event, row, val) {
      event.stopPropagation();
      this.dialog.visible = true;
      this.status = val;
      this.idList = row.id;
      if (val == "open") {
        this.dialog.title = this.$t('stationReport.openStation');
      } else {
        this.dialog.title = this.$t('stationReport.closeStation');
      }
    },
    /**
     * @description: 删除操作
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
     async handleDel(event, val) {
      event.stopPropagation();
      const results = await this.MixinsConfirm(this.$t("stationReport.isDelete"));
      if (results) {
        stationRemove({ ids: val.id }).then((res) => {
          if (res.code == 200) {
            this.$refs.grid.tableInitial(false);
          }
        });
      }
    },
    /**
     * @description: 电站切换
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */

    async handleClick(data) {
      const results = await this.MixinsConfirm(this.$t('stationReport.Switch'));
      if (results) {
        switchStation({ stationId: data.id }).then(resp => {
          if (resp.code === 200) {
            const station = util.readSession("userInfo");
            station["stationId"] = data.id;
            station["stationName"] = data.name;
            util.writeSession("userInfo", station);
            util.writeSession("stationId", data.id);

            // util.removeSession("stationId");
            // util.writeSession("station", data);
            this.$store.dispatch("user/changeStation", data.name);
            let addr = localStorage.getItem('lang')==='zh'?
                util.defaultValue(data.country) +" "+
                util.defaultValue(data.province) +" "+
                util.defaultValue(data.city) +" "+
                util.defaultValue(data.district)
                :
                util.defaultValue(data.district)+" "+
                util.defaultValue(data.city) +" "+
                util.defaultValue(data.province) +" "+
                util.defaultValue(data.country);
            this.$store.dispatch("user/changeStationAddress", addr);
            this.$message.success(this.$t('stationReport.OperationSuccess'));
            this.$router.push('/dashboard')
          }
        });
      }
    },
    /**
     * @description: 获取验证码
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    getVerify() {
      let params = {
        businessCode: "stationControl",
        captchaType: 0,
        resultType: 1
      };
      captcha(params).then(res => {
        if (res.data.code == 200) {
          this.imgSrc = res.data.data;
        }
      });
    }
  },
  created() {
    this.getVerify();
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "../style/index";
</style>
