//写一个默认输出LayoutLogicHoneybee类
import * as util from "@/utils";
import current from "element-ui/packages/table/src/store/current";

export default class LayoutLogicBase{
  constructor(canvas) {
    this.canvas  = canvas;

    this.inverter = {
      width:80,
      height:50
    };

    this.honeybee = {
      width: 49,
      height: 70
    }

    this.inverter_to_honeybee_horizntal_distance = 20;
    this.honeybee_to_honeybee_horizontal_distance = 2;
    this.honeybee_to_honeybee_vertical_distance = 2;

    this.input_height = 25;//canvas上部input输入框到顶部的高度

    this.name = 'LayoutLogicBase';
  }

  drawText(tag, id, left, top,angle,zoom=1) {
    const text = new fabric.Text(`${tag}`, {
      fontSize: 12,
      left: left,
      top: top,
      markId: id,
      fill: "#fff",
      originX: "center",
      originY: "center",
      scaleX: zoom,
      scaleY: zoom,
    });
    // if (angle !== 0) {
    //   text.set({
    //     angle: -angle,
    //     left: left + x,
    //     top: top + y,
    //   });
    // }
    text.moveTo(5);
    return text;
  }

  /**
   * @description: 返回文字与元素组合
   * @param {type}
   * @return {type}
   */
  group(rect, text, id, drawType, boolean, angle) {
    //设置text的left和top
    // text.set({
    //   left: rect.left,
    //   top: rect.top,
    // });
    let group = new fabric.Group([rect, text], {
      // hasControls: true,
      lockScalingX: true,
      lockScalingY: true,
      markId: id,
      typeStick: drawType,
      type: "group",
      transparentCorners: false,
      bgType: boolean ? "bgType" : null,
      selectable: false,
      selection: false,
      hasControls: false,
      lockRotation: false, //设置不可旋转
      cornerSize: 10, //设置拖拽大小
      cornerStyle: "circle", //设置拖拽形状
      cornerColor: "#00ff00",
      rotatingPointOffset: 20, //设置旋转拖拽长度
      angle: angle,
      // originX: "center",
      // originY: "center",
      // left:rect.left,
      // top:rect.top,
      // Xaxis: canvasObject.left,
      // Yaxis: canvasObject.top,
    });
    return group;
  }

//计算honeybee逻辑分组的最大长度和高度
  maxGroupLengthAndHeight(pvList) {
    //将this.pvList按照GroupId分组
    const groupList = pvList.reduce((prev, cur) => {
      (prev[cur.GroupId] = prev[cur.GroupId] || []).push(cur);
      return prev;
    }, {});
    //算出groupList的分组数
    const groupLength = Object.keys(groupList).length;
    //找出groupList的最大分组数
    const maxGroupLength = Math.max.apply(
      null,
      Object.keys(groupList).map((item) => groupList[item].length)
    );

    let devices_width = this.honeybee.width*maxGroupLength + (maxGroupLength-1)*this.honeybee_to_honeybee_horizontal_distance + this.inverter_to_honeybee_horizntal_distance + this.inverter.width;
    let devices_height = this.honeybee.height*groupLength + (groupLength-1)*this.honeybee_to_honeybee_vertical_distance;
    return {devices_width,devices_height};
  }

  //计算缩放比
  calcZoom(pvList) {
    let {devices_width,devices_height} = this.maxGroupLengthAndHeight(pvList);
    let height = this.canvas.height - this.input_height;
    if(devices_width > this.canvas.width || devices_height > height){
      let zoom = Math.min(this.canvas.width/devices_width,height/devices_height);
      return zoom;
    }
    return 1;
  }

  /**
   * 在排除掉canvas顶部的20高度后，计算出的余下部分的中心点坐标，
   */
  calcCenterPoint() {
    let centerPoint = {
      x: this.canvas.width / 2,
      y: (this.canvas.height - this.input_height) / 2 + this.input_height,
    };
    return centerPoint;
  }

  //计算inverter坐标
  calcInverterPoint(centerPoint) {

  }

  /**
   * groups数据格式如下
   * {
   * 			"Id": 1,
   * 			"Label": "A",
   * 			"InverterId": 1
   * 		}, {
   * 			"Id": 2,
   * 			"Label": "B",
   * 			"InverterId": 2
   * 		}, {
   * 			"Id": 3,
   * 			"Label": "C",
   * 			"InverterId": 3
   * 	}]
   * @description: 计算inverters位置
   * @param groups
   * @returns {{inverters_height: number, inverters_top: number, inverters_width: number, inverters_left: number}}
   */
  calcInvertersPosition(groups,pvList) {
    //计算设备总的宽度和高度
    let {devices_width,devices_height} = this.maxGroupLengthAndHeight(pvList);
    let zoom = this.calcZoom(pvList);

    //计算出canvas中心点坐标
    let {x,y} = this.calcCenterPoint();

    //计算出第一个inverter的坐标吗
    let first_inverter_x = x-(devices_width*zoom)/2;
    let first_inverter_y = y-(devices_height*zoom)/2;
    //计算出多少个inverters


    //将数组按照InverterId分组
    const group = groups.reduce((prev, cur) => {
      (prev[cur.InverterId] = prev[cur.InverterId] || []).push(cur);
      return prev;
    }, {});
    //将groups数组对象，先按照InverterId排序，InverterId是数字，如果InverterId相同，
    // 再按照Label排序，其中Label的值是ABCDEF二十六个字母，如果26个字母用完了的话，就是AA、AB以此循环
    const groupList = groups.sort((a, b) => {
      if (a.InverterId === b.InverterId) {
        return a.Label > b.Label ? 1 : -1;
      } else {
        return a.InverterId > b.InverterId ? 1 : -1;
      }
    });

    //计算出每个inverter有多少个group的分组
    let count_groups_per_inverter = Object.keys(group).map((item) => {
      let length = group[item].length;
      return length;
    });

    //循环count_groups_per_inverter数组，计算出每个inverter的坐标
    let inverters = [];
    let inverter_x = first_inverter_x;
    let inverter_y = first_inverter_y;
    inverters.push({x:inverter_x,y:inverter_y});
    for (let i = 1; i < count_groups_per_inverter.length; i++) {
      inverter_y += count_groups_per_inverter[i-1] * (this.honeybee.height + this.honeybee_to_honeybee_vertical_distance) * zoom;

      let inverter = {
        x: inverter_x,
        y: inverter_y
      };
      inverters.push(inverter);
    }
    return inverters;
  }

  //第一个inverter的坐标
  firstInverterPoint(pvList) {
    let {devices_width,devices_height} = this.maxGroupLengthAndHeight(pvList);
    let zoom = this.calcZoom(pvList);
    let {x,y} = this.calcCenterPoint();
    //计算出第一个inverter的坐标
    let first_inverter_x = x-(devices_width*zoom)/2;
    let first_inverter_y = y-(devices_height*zoom)/2 + this.input_height;
    if(first_inverter_x < this.inverter.width*zoom/2){
      first_inverter_x = this.inverter.width*zoom/2;
    }

    return {first_inverter_x,first_inverter_y};
  }

  restZoom(){
    this.zoomPoint = new fabric.Point(
      0,
      0
    );
    this.canvas.zoomToPoint(this.zoomPoint, 1);
  }

  /**
   * 数据结构如下所示
   * [
   *  {
   *    Id:1,
   *    Modulepower:275,
   *  }
   * ]
   * 找出出最大Modulepower值,必须单例模式实现
   */
  maxModulepower(data){
    let max = 0;
    data.pvmoduletypes.forEach((item)=>{
      if(item.Modulepower > max){
        max = item.Modulepower;
      }
    });
    if(max<250){
      max = 250;
    }
    return max;
  }

  //单例模式
  static getInstance(){
    if(!this.instance){
      this.instance = new this();
    }
    return this.instance;
  }


}
