/*
 * @Author: sun.mengmeng
 * @Date: 2021-09-14 08:52:16
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-10-23 17:17:41
 * @Description: file content
 */
const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  menus: state => state.user.menus, // 菜单权限
  roles: state => state.user.roles, // 角色权限控制按钮
  stationName: state => state.user.stationName,
  stationAddress: state => state.user.stationAddress
};
export default getters;
