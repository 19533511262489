<template>
  <div class="design-layout" ref="box">
    <div class="desing-tool">
      <!--工具栏-->
      <ul class="tool-type">
        <!--画布鼠标指针坐标-->
        <li class="coord-gird" style="width:230px; display: flex">
          <el-input v-for="item of coordData" :key="item.id" :value="item.value ? item.value.toFixed(0) : ''" disabled style="width: 150px; margin-right: 20px">
              <template slot="prepend">{{ item.title }}</template>
          </el-input>
        </li>
        <li class="coord-gird" style="width:270px; display: flex">
          <el-input v-model="canvasWidth" @change="canvasSizeChange" style="width: 155px; margin-right: 10px">
              <template slot="prepend">Width</template>
          </el-input>
          <el-input v-model="canvasHeight" @change="canvasSizeChange" style="width: 155px; margin-right: 10px">
              <template slot="prepend">Height</template>
          </el-input>
        </li>
        <li>
          <el-button @click="setZoom(0.1)">+</el-button>
          <el-button>{{ getZoom }}</el-button>
          <el-button @click="setZoom(-0.1)">-</el-button>
        </li>
        <li class="type-item">
          <i class="el-icon-full-screen iconfont item-icon" @click="isScreenFull()"></i>
        </li>
        <li class="type-item" @click="startPanning = !startPanning; canvas.selection = !canvas.selection;">
          <i class="el-icon-thumb iconfont item-icon" v-bind:class="{ active: startPanning }"></i>
        </li>
<!--        <li-->
<!--          v-for="(item, index) of renderTool"-->
<!--          :key="index"-->
<!--          class="type-item"-->
<!--          @click="selectTool(item)">-->
<!--          <span :class="['iconfont', 'item-icon', `icon-${item.icon}`]"></span>-->
<!--        </li>-->
        <li class="type-grid">
          <el-radio-group v-model="girdVisible" @change="gridOpen">
            <el-radio :label="true">Show grid</el-radio>
            <el-radio :label="false">Snap to grid</el-radio>
          </el-radio-group>
        </li>
        <li class="type-item type-color" style="">
          <span :class="['iconfont', 'item-icon', `icon-color_lens`]"></span>
          <colorPicker
            class="item-color"
            @change="headleChangeColor"
            size="medium"
            v-model="backgroundColor"
          >
          </colorPicker>
        </li>
        <li class="type-item">
          <i @click="doDelete" class="el-icon-delete-solid type-delete"></i>
        </li>
        <li class="type-save" :class="{'disabled' : !canUndo}" @click="historyState('Undo')">
          {{ $t("config.undo") }}
        </li>
        <li class="type-save" :class="{'disabled' : !canRedo}" @click="historyState('Redo')">
          {{ $t("config.redo") }}
        </li>
<!--        <li class="type-save" @click="stateIndex&#45;&#45;; historyState(stateIndex)">-->
<!--          <el-button :disabled="!canUndo" style="background: transparent;">-->
<!--            撤销-->
<!--          </el-button>-->
<!--        </li>-->
<!--        <li class="type-save" @click="stateIndex++; historyState(stateIndex)">-->
<!--          <el-button :disabled="!canRedo">-->
<!--            恢复-->
<!--          </el-button>-->
<!--        </li>-->
        <li class="type-save" @click="saveObj">
          {{ $t("config.save") }}
        </li>
      </ul>
    </div>
    <div class="design-show">
      <!--设计栏左侧-->
      <div class="show-left">
        <!--元素栏-->
        <ul class="left-img">
          <li v-for="(item, index) of imgList" :key="index" class="left-item">
            <img class="item-img" @click="addLayer(item, index)" @mousedown="recordImg(item, index)" :src="item.url" /><br/>
            <span>{{ $t(`config.${item.type}`) }}</span>
          </li>
        </ul>
      </div>
      <div
        class="show-canvas x-scroll"
        ref="refName"
        :style="{
          overflow: 'auto',
          backgroundImage: `url(${imgBg})`,
          backgroundSize: '100% auto',
          backgroundRepeat: 'no-repeat'
          }"
      >
        <canvas id="canvas" :width="canvasWidth" :height="canvasHeight">
        </canvas>
      </div>
      <div class="show-right">
          <ul class="right-rect" v-if="selectRect">
            <li
              v-for="(item, index) of rectCoord"
              :key="index"
              class="rect-input">
                <p class="input-name">{{ item.name }}</p>
                <div class="input-text">
                  <el-input
                    v-if="item.disabled"
                    v-model="item.value"
                    :disabled="item.disabled"
                    @change="(event) => inputChange(event, item)"></el-input>
                  <el-input-number
                    v-else
                    v-model="item.value"
                    style="width: 100%"
                    controls-position="right"
                    @change="(event) => inputChange(event, item)"
                    :min="0"></el-input-number>
                </div>
            </li>
          </ul>
          <ul class="right-rect" v-if="isGrouoShow.length > 0">
            <li class="rect-item">
                <p class="item-label">Horization</p>
                <p class="item-set" @click="setAlign('Horization')">Set</p>
            </li>
            <li class="rect-item">
                <p class="item-label">Vertical</p>
                <p class="item-set" @click="setAlign('Vertical')">Set</p>
            </li>
          </ul>
          <div class="right-update" v-if="selectRect">
              <el-button type="primary" style="width: 100%" round>Update</el-button>
          </div>
          <!--canvas缩略图-->
          <canvas id="minimap" width="200" height="165"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutLogicHoneybee from "@/logic/LayoutLogicHoneybee";
// 引入全屏插件
import screenfull from "screenfull";
import * as util from "@/utils";
import { setLayoutInfo, getLayoutTree, stationDetail, stationDetailAwait, detailConfig } from "@/api/config";
import message from "element-ui/packages/message";

export default {
  data() {
    return {
      // 鼠标选中的多个元素
      isGrouoShow: [],
      // 鼠标选中的单个元素
      selectRect: null,
      // 元素类型 text：文本 stick：线条
      drawType: "",
      // 矩形数据列表
      rectCoord: [
        {
          name: "Location",
          value: "",
          disabled: true,
        },
        {
          name: "Mac",
          value: "",
          disabled: true,
        },
        {
          name: "Angle",
          value: 0,
          disabled: false,
        },
        {
          name: "X",
          value: "",
          disabled: false,
        },
        {
          name: "Y",
          value: "",
          disabled: false,
        },
      ],
      // 工具列表
      renderTool: [
        {
          icon: "font-size-two",
          type: "text",
        },
        {
          icon: "linear_scale",
          type: "stick",
        },
        // {
        //   icon: "layer2",
        //   type: "moveUp",
        // },
        // {
        //   icon: "layer",
        //   type: "moveDown",
        // },
      ],
      // 坐标相关参数
      coordData: [
        {
          name: "x坐标",
          value: "",
          id: "x",
          title: "X",
        },
        {
          name: "y坐标",
          value: "",
          id: "y",
          title: "Y",
        },
      ],
      clickIndex: 0,
      valueIndex: 1,
      // 元素类型
      imgList: [
        {
          url: require("@/assets/design/powergrid.svg"),
          type: "powergrid",
        },
        {
          url: require("@/assets/design/compass.svg"),
          type: "compass",
        },
        {
          url: require("@/assets/design/swarm.svg"),
          type: "swarm",
        },
      ],
      // 画布背景图
      storageImage: null,
      // 画布背景色
      backgroundColor: "#ffffff",
      backgroundText: false,
      // 画布
      canvas: null,
      // 画布宽度
      canvasWidth: 3000,
      // 画布高度
      canvasHeight: 3000,
      // 绘制移动计数器
      moveCount: 1,
      // 绘制状态
      doDrawing: false,
      panning: false,
      startPanning: false,

      // 标尺是否显示
      girdVisible: false,
      // 标尺刻度文字显示
      horizontalData: [],
      verticalData: [],

      // 画布相关参数
      mouseFrom: {},
      mouseTo: {
        x: 0,
        y: 0,
      },
      mousePoint: {
        x: 0,
        y: 0,
      },
      // 放大缩小相关参数
      zoomOutValue: 1,
      zoomInValue: 1,
      zoom: 0,
      // 初始时缩放原点的位置设为（0,0），这是页面的左上顶点
      zoomPoint: new fabric.Point(0, 0),
      // 初始时，前一次缩放原点同样为(0,0)
      lastzoomPoint: { x: 0, y: 0 },
      // 进行缩放，需要对此刻缩放位置进行保存，来计算出缩放原点，此刻初始时设为0,0
      lastmousePoint: { x: 0, y: 0 },
      // 表示为上一次的缩放倍数，此刻设为1
      lastzoom: 1,

      miniMap: null,
      //线段相关数据
      stickIndex: 0,
      stickList: [],

      //组串个数
      pvPanels: [],
      // 缩略图框:
      rect: null,

      // 撤销恢复canvas
      isLoadCanvas: false,
      canvasState: [],
      stateIndex: -1,
      initSuccess: false,
      initPic: false,
      imgBg: ""
    };
  },
  computed: {
    /**
     * 获取canvas缩放比例
     * @returns {number|string}
     */
    getZoom() {
      if (this.canvas) {
        return Number(this.canvas.getZoom()).toFixed(1);
      } else {
        return 1;
      }
    },
    canUndo() {
      return this.stateIndex > 0;
    },
    canRedo() {
      return this.stateIndex < this.canvasState.length - 1;
    },
    isCollapse() {
      return this.$store.getters["settings/collapse"];
    },
  },
  methods: {
    // 获取电站详情
    getStatioDetail(id) {
      const params = {
        id: id,
      };
      detailConfig(params).then((resp) => {
        if (resp.code === 200) {
          const { picname } = resp.data;
          if (picname) {
            this.imgBg = this.$t("common.picUrl") + util.readSession("userInfo").stationId + "/" + picname;
          } else {
            this.imgBg = "";
          }
        }
      });
    },

    // 全屏方法
    isScreenFull() {
      if (!screenfull.isEnabled) {
        // 如果不支持进入全屏，发出不支持提示
        this.$message({
          message: "您的浏览器版本过低不支持全屏显示！",
          type: "warning",
        });
        return false;
      }
      // 此处填入需要全屏的ref属性值即可
      screenfull.toggle(this.$refs.box);
    },

    // 设置放大缩小
    setZoom(val) {
        let zoom = this.canvas.getZoom() + parseFloat(val);
        zoom = Math.max(0.2, zoom);
        zoom = Math.min(5, zoom);
        this.canvas.setZoom(zoom);
    },
    // 画布大小变化
    canvasSizeChange() {
      this.canvas.setWidth(this.canvasWidth)
      this.canvas.setHeight(this.canvasHeight)
      this.canvas.discardActiveObject();
      this.canvas.requestRenderAll();
    },
    // 选中多个元素对齐
    setAlign(type) {
      const obj = this.canvas.getActiveObject();
      if (type === "Vertical") {
        // 竖直
        if (obj) {
          let { _objects } = obj;
          // 获取对象数组_objects中key为angle的值
          const angleArr = _objects.map((item) => item.angle);

          // 将对象_objects数组按照top值进行排序
          _objects.sort((a, b) => {
            return a.top - b.top;
          });
          _objects = LayoutLogicHoneybee.scalcHoneybeesHeight(_objects);

          const first = _objects[0]._objects[0];
          _objects.forEach((v, index) => {
            let new_angle = LayoutLogicHoneybee.calcRotateAngle(v.angle);
            v.set('angle', new_angle).setCoords();
            v.set({
              left: _objects[0].left,
            });
          });
          this.canvas.discardActiveObject();
          this.canvas.requestRenderAll();
        }
      } else if (type === "Horization") {
        let { _objects } = obj;
        // 将对象_objects数组按照left值进行排序
        _objects.sort((a, b) => {
          return a.left - b.left;
        });

        _objects = LayoutLogicHoneybee.scalcHoneybeesPosition(_objects);

        // 水平
        _objects.forEach((v, index) => {
          let new_angle = LayoutLogicHoneybee.calcRotateAngle(v.angle);
          v.set('angle', new_angle).setCoords();

          v.set({
            top: _objects[0].top,
          });
        });
        this.canvas.discardActiveObject();
        this.canvas.requestRenderAll();
      } else {

      }
    },
    // 删除元素
    doDelete() {
      const active = this.canvas.getActiveObject();
      if (active) {
        this.canvas.remove(active);
        this.selectRect = null;
        this.isGrouoShow = [];
        this.updateMiniMap();
        this.canvas.renderAll();
      }
    },
    // 处理单组折线的数据
    handleSingle(filter) {
      if (filter.length < 1) return;
      let linesPoint = [];
      linesPoint = filter.map((v) => {
        return {
          Xaxis1: v.line1.x1,
          Yaxis1: v.line1.y1,
          Xaxis2: v.line1.x2,
          Yaxis2: v.line1.y2,
          LineWidth: 1,
        };
      });
      return linesPoint;
    },

    /**
     * 获取数据
     */
    getTreeData() {
      const stationId = util.readSession("stationId");
      if (!stationId) return;

      this.getStatioDetail(stationId);

      const params = {
        stationId: stationId,
        step: 5,
      };
      stationDetail(params).then((resp) => {
        if (resp.code === 200) {
          const { inverters, pvpanels, assist } = resp.data;
          this.pvPanels = pvpanels;
          // 逆变器
          inverters.forEach((v) => {
            this.reshowReverst(v);
          });

          // 光伏面板
          pvpanels.forEach((v) => {
              this.reshowRect(v);
          });

          const { Lines, LocalResources } = assist;
          if (LocalResources) {
            LocalResources.forEach((v) => {
              this.reshowImage(v);
            });
          }

          // 线
          if (Lines !== undefined) {
            Lines.forEach((v) => {
              this.showStick(v);
            });
          }
        }
      }).then(() => {
        // 执行miniMap
        this.initMiniMap();
        this.canvas.renderAll();
        this.initSuccess = true;

        if (this.initPic && this.initSuccess) {
          this.updateCanvasState();
        }
      });
    },

    /**
     * @description: 处理stickman数据结构
     * @param {type}
     * @return {type}
     */
    handleStickMan() {
      const objects = this.canvas.getObjects();
      // 记录有多少组的折线
      this.stickList = Array.from(new Set([...this.stickList]));
      let all = [];
      this.stickList.forEach((v) => {
        let realData = [];
        const filter = objects.filter(
          (item) => item.typeStick && item.typeStick === `stick${v}`
        );
        realData = filter.filter((v1) => v1.line1 && v1.line2);
        all.push(realData);
      });
      let arr = [];
      for (let i = 0; i < all.length; i++) {
        const json = this.handleSingle(all[i]);
        if (json) {
          arr.push(json);
        }
      }
      return arr;
    },
    // 选择工具类
    selectTool(item) {
      const { type } = item;
      if (type === "text") {
        this.drawType = "text";
      } else if (type === "stick") {
        this.drawType = "stick";
      } else {
        this.drawType = "";
        const active = this.canvas.getActiveObject();
        if (active) {
          if(type==='moveUp'){
            active.moveTo(2);
          }else if(type==='moveDown'){
            active.moveTo(1);
          }
        }
      }
    },
    // 保存数据
    saveObj() {
      this.canvas.discardActiveObject();
      const id = util.readSession("stationId");
      if (!id) return;
      const all = this.canvas.getObjects();
      const icons = all.filter((v) => v.drawType && v.drawType === "icon");
      const string = all.filter((v) => v.typeStick && v.typeStick === "string"); //组串
      const txts = all.filter(v=>v.drawType && v.drawType === "text");
      const inverter = all.filter(
        (v) =>
          v.typeStick &&
          v.typeStick === "inverter" &&
          v._objects &&
          v._objects[0].type === "inverter"
      ); // 逆变器

      let inverterData = [];
      let stringData = [];
      let iconData = [];
      if (string.length > 0) {
        // 组串数据
        string.forEach((v) => {
          const {
            macId,
            tagName,
            groupId,
            honeybeeOutType,
            pVModuleTypeId,
            currentLeft,
            currentTop,
          } = v._objects[0];
          const json = {
            GroupId: groupId,
            HoneybeeMac: macId,
            Location: tagName,
            HoneybeeOutput: honeybeeOutType,
            PVModuleTypeId: pVModuleTypeId,
            // Xaxis: v.currentLeft ? v.currentLeft : currentLeft,
            Xaxis: v.left,
            // Yaxis: v.currentTop ? v.currentTop : currentTop,
            Yaxis: v.top,
            Angle: v.angle ? (v.angle<0?180+v.angle:(v.angle>180?360-v.angle: v.angle)) : 0,
          };
          stringData.push(json);
        });
      }

      if (inverter.length > 0) {
        //逆变器数据
        inverter.forEach((v) => {
          const { aCoords, tagName, inverterId, currentTop, currentLeft } =
            v._objects[0];
          const json = {
            Label: tagName,
            Id: inverterId,
            // Xaxis: v.currentLeft ? v.currentLeft : currentLeft,
            Xaxis: v.left,
            // Yaxis: v.currentTop ? v.currentTop : currentTop,
            Yaxis: v.top,
            Angle: v.angle ? (v.angle<0?180+v.angle:(v.angle>180?360-v.angle: v.angle)) : 0,
          };
          inverterData.push(json);
        });
      }
      const stick = this.handleStickMan();
      const txtinfos = txts.map(v=>{
        return {
          Txt: v.text,
          Xaxis: v.left,
          Yaxis: v.top
        };
      });
      const iconImage = icons.map((v) => {
        return {
          Xaxis: v.currentLeft,
          Yaxis: v.currentTop,
          ImageName: v.typeName,
          ResourceType: v.typeIndex,
        };
      });

      const params = {
        assist: {
          Lines: stick.flat(),
          TxtInfos: txtinfos,
          LocalResources: iconImage,
        },
        inverters: inverterData,
        pvpanels: stringData,
        stationId: id,
      };
      if (screenfull.isFullscreen) {
        screenfull.exit();
      }
      setLayoutInfo(params).then((resp) => {
        if (resp.code === 200) {
          this.$message.success(this.$t("config.success"));
          this.stateIndex = 0;
          this.canvasState = [];
          this.updateCanvasState();
        }
      });
    },

    // 设置移动距离与角度
    inputChange(value, item) {
      if (item.disabled || !this.selectRect) {
        return;
      }
      const { name } = item;
      if (name === "X") {
        this.selectRect.set("left", value).setCoords();
      } else if (name === "Y") {
        this.selectRect.set("top", value).setCoords();
      } else {
        this.selectRect.set("angle", value).setCoords();
        const { angle, _objects } = this.selectRect;
        _objects[1].set("angle", -angle).setCoords();
      }
      this.canvas.requestRenderAll();
    },

    /**
     * @description: 坐标系开关
     * @param {type}
     * @return {type}
     */
    gridOpen() {
      if (this.girdVisible) {
        this.drawTable();
      } else {
        this.removeTable();
      }
      this.updateMiniMap();
    },

    /**
     * @description: 选择颜色
     * @param {type}
     * @return {type}
     */
    headleChangeColor(e) {
      this.backgroundColor = `${e}`;
      const dom = document.querySelector(".canvas-container");
      dom.style.backgroundColor = this.backgroundColor;
    },

    /**
     * @description: 删除表格
     */
    removeTable() {
      if (this.canvas) {
        const all = [...this.horizontalData, ...this.verticalData];
        for (let [key, value] of Object.entries(all)) {
          this.canvas.remove(value);
        }
        this.horizontalData = [];
        this.verticalData = [];
      }
    },

    /**
     * @description: 绘制表格
     * @param {type}
     * @return {type}
     */
    drawTable(disBox = 50, color = "#70a436") {
      this.removeTable();
      const { width, height } = this.canvas;
      this.horizontalData = [];
      this.verticalData = [];
      const dis = disBox;
      const oneDis = dis * 5;
      const currentZoom = this.canvas.getZoom();
      const options = {
        distance: dis,
        width: this.canvas.width / currentZoom + oneDis,
        height: this.canvas.height / currentZoom + oneDis,
        param: {
          stroke: color,
          strokeWidth: 1,
          selectable: false,
          evented: false,
          excludeFromExport: true,
          hasControls: false,
          perPixelTargetFind: false,
          opacity: 0.3,
          tableRule: true,
        },
      };
      const gridLen = width / options.distance;
      const gridHen = height / options.distance;
      let max = 0;
      let min = 0;
      if (gridLen >= gridHen) {
        max = gridLen;
        min = gridHen;
      } else {
        max = gridHen;
        min = gridLen;
      }

      // 竖直方向
      for (let i = 0; i < gridHen; i++) {
        const distance = i * options.distance;
        if (distance <= height && distance >= 0) {
          const vertical = new fabric.Line(
            [0, distance, width, distance],
            options.param
          );
          // 将横竖两排添加到画布
          this.canvas.add(vertical);
          vertical.moveTo(1);
          this.verticalData.push(vertical);
        }
      }

      // 水平方向
      for (let i = 0; i < gridLen; i++) {
        const distance = i * options.distance;
        if (distance <= width && distance >= 0) {
          const horizontal = new fabric.Line(
            [distance, 0, distance, height],
            options.param
          );
          //将横竖两排添加到画布
          this.canvas.add(horizontal);
          horizontal.moveTo(1);
          this.horizontalData.push(horizontal);
        }
      }
    },

    // 显示坐标x、y坐标
    showCoord(pointer) {
      const posX = pointer.x;
      const posY = pointer.y;
      this.coordData[0].value = posX;
      this.coordData[1].value = posY;
    },

    /**
     * @description:坐标转换
     * @param {type}
     * @return {type}
     */
    transformMouse(mouseX, mouseY) {
      return {
        x: mouseX / window.devicePixelRatio,
        y: mouseY / window.devicePixelRatio,
      };
    },

    /**
     * @description: 鼠标滑动时触发事件
     * @param {type}
     * @return {type}
     */
    mouseMove(options) {

      // 判断辅助线在画布范围之内显示
      const pointer = this.canvas.getPointer(options.e, false);

      // 显示鼠标指针坐标值
      this.showCoord(pointer);

      // 当选择移动画布模式时触发
      if (options && options.e && this.panning && this.startPanning) {
          const point = new fabric.Point(
              options.e.movementX,
              options.e.movementY
          );

          this.canvas.relativePan(point);
          this.mousePoint.x += options.e.movementX;
          this.mousePoint.y += options.e.movementY;
          this.canvas.renderAll();

          this.rect.set("left", this.rect.get("left") - options.e.movementX / (this.canvas.width / this.miniMap.width));
          this.rect.set("top", this.rect.get("top") - options.e.movementY / (this.canvas.height / this.miniMap.height));
          this.miniMap.renderAll();
      }

      // 减少绘制频率
      if (this.moveCount % 2 && !this.doDrawing) {
        return;
      }
      this.moveCount++;
      this.mouseTo.x = this.lastzoomPoint.x + (options.pointer.x - this.zoomPoint.x - this.mousePoint.x) / this.canvas.getZoom();
      this.mouseTo.y = this.lastzoomPoint.y + (options.pointer.y - this.zoomPoint.y - this.mousePoint.y) / this.canvas.getZoom();
    },

    /**
     * 记录当前拖拽的元素
     * @param item
     * @param index
     */
    recordImg (item, index) {
        this.index = index;
        this.currentMoveImg = item;
    },

    /**
     * @description: 鼠标滑动时触发事件
     * @param {type}
     * @return {type}
     */
    mouseOver(options) {
      if (this.currentMoveImg) {
        this.clickIndex += 1;
        const id = util.generateId(); //生成唯一标识
        fabric.Image.fromURL(
            this.currentMoveImg.url,
            (img) => {
                img
                    .set({
                        left: this.coordData[0].value,
                        top: this.coordData[1].value,
                        drawType: "icon",
                        typeName: this.currentMoveImg.type,
                        typeIndex: this.index,
                        markId: id,
                        originX: "center",
                        originY: "center",
                        hasControls: false,
                        currentLeft: this.coordData[0].value,
                        currentTop: this.coordData[1].value,
                        scaleX: 1,
                        scaleY: 1,
                    })
                    .scale(0.3);

                this.canvas.add(img);
                img.moveTo(1); // 设置层级

                // 更新miniMap
                this.updateMiniMap();
                this.canvas.renderAll();
                this.currentMoveImg = null;
            },
            { crossOrigin: "Anonymous" }
        );
      }
    },

    /**
     * @description: 鼠标按下时触发事件
     * @param {type}
     * @return {type}
     */
    mouseDown(options) {

      // 画布移动
      this.panning = true;

      // 画布可绘画
      this.doDrawing = true;

      this.mouseFrom.x = this.lastzoomPoint.x + (options.pointer.x - this.zoomPoint.x - this.mousePoint.x) / this.canvas.getZoom();
      this.mouseFrom.y = this.lastzoomPoint.y + (options.pointer.y - this.zoomPoint.y - this.mousePoint.y) / this.canvas.getZoom();

      // 选中元素
      if (options.target) {

        this.panning = false;
        const { type } = options.target;

        if (type && type === "group") {
          this.selectRect = options.target;
          const {
            left,
            top,
            _objects,
            angle,
            typeStick,
            translateX,
            translateY,
          } = options.target;
          if (typeStick === "string") {

          }
          this.rectCoord[3].value = translateX;
          this.rectCoord[4].value = translateY;
          const { macId, tagName } = _objects[0];

          this.rectCoord[0].value = tagName;
          this.rectCoord[1].value = macId;
          this.rectCoord[2].value = angle ? (angle < 0 ? 360 + angle : angle) : 0;
        } else {
          this.selectRect = null;
          this.isGrouoShow = [];
        }
      } else {
        this.selectRect = null;
        this.isGrouoShow = [];
      }

      // 绘制元素
      if (this.drawType === "text") {
        this.drawText();
        this.drawType = "";
        this.updateMiniMap();
      } else if (this.drawType === "stick") {
        this.stickIndex += 1;
        this.stickList.push(this.stickIndex);
        this.drawStick();
        this.drawType = "";
        this.updateMiniMap();
      } else {
        this.drawType = "";
      }
    },

    /**
     * @description: 鼠标松开时触发事件
     * @param {type}
     * @return {type}
     */
    mouseUp(options) {
      this.isMouseDown = false;

      // 移动画布
      this.panning = false;

      const xy = this.transformMouse(options.e.offsetX, options.e.offsetY);
      this.mouseTo.x = this.lastzoomPoint.x + (options.pointer.x - this.zoomPoint.x - this.mousePoint.x) / this.canvas.getZoom();
      this.mouseTo.y = this.lastzoomPoint.y + (options.pointer.y - this.zoomPoint.y - this.mousePoint.y) / this.canvas.getZoom();
      this.canvas.renderAll();
      this.moveCount = 1;
    },

    /**
     * @description: 添加背景图
     * @param {type}
     * @return {type}
     */
    showImage() {
      const imgObj = new Image();
      let imageSrc = "";
      imgObj.src = require("@/assets/design/demo.jpg");
      imageSrc = require("@/assets/design/demo.jpg");
      imgObj.onload = () => {
        if (this.storageImage) {
          this.canvas.remove(this.storageImage);
          this.storageImage = null;
        }
        let image = new fabric.Image(imgObj);
        //获取图片宽，高，将数值大的作为放大比例
        let scale = 1;

        image.setSrc(
          imageSrc,
          (img) => {
            const filter = new fabric.Image.filters.Blur({
              blur: 0,
            });
            img.set({
              originX: "left",
              scaleX: 1,
              scaleY: 1,
              originY: "top",
              left: 0,
              top: 0,
              repeat: "no-repeat",
              crossOrigin: "Anonymous",
              toolType: "image", //设置标识，双击删除时不删除图片
              selectable: false,
              selection: false,
              hasControls: false, //无法编辑操作
              lockMovementX: true, //将图片x,y轴不能移动
              lockMovementY: true,
              moveCursor: "default",
              hasBorders: false,
              imageSmoothing: true,
              width: 60,
              height: 60,
            });
            this.canvas.add(img);
            img.filters.push(filter);
            img.applyFilters();
            this.storageImage = img;
            img.moveTo(0); //设置层级

            this.canvas.renderAll();
          },
          { crossOrigin: "anonymous" }
        );
      };
    },

    /**
     * @description: 返回文字与元素组合
     * @param {type}
     * @return {type}
     */
    elementGroup(canvasObject, text, id, drawType, angle,Xaxis=0,Yaxis=0) {
      const group = new fabric.Group([canvasObject, text], {
        left: Xaxis,
        top: Yaxis,
        hasControls: true,
        lockScalingX: true,
        lockScalingY: true,
        selection: true,
        selectable: true,
        markId: id,
        typeStick: drawType,
        originX: "center",
        originY: "center",
        type: "group",
        transparentCorners: false,
        cornerSize: 10, //设置拖拽大小
        cornerStyle: "circle", //设置拖拽形状
        cornerColor: "#00ff00",
        rotatingPointOffset: 20, //设置旋转拖拽长度
        angle: angle,
      });
      return group;
    },

    /**
     * @description: 绘制文字
     * @param {type}
     * @return {type}
     */
    drawText() {
      // 生成唯一标识
      const id = util.generateId();
      const text = new fabric.IText(this.$t("config.testText"), {
        left: this.mouseFrom.x,
        top: this.mouseFrom.y,
        drawType: 'text',
        width: 150,
        height: 50,
        fontSize: 12,
        strokeWidth: 1,
        borderColor: "#70a436",
        editingBorderColor: "#70a436",
        padding: 5,
        fill: "#70a436",
        hasControls: false,
        transparentCorners: false,
        markId: id,
      });
      this.canvas.add(text);
      // this.textbox.enterEditing();
      // this.textbox.hiddenTextarea.focus();
      text.moveTo(5);
      // this.textbox.exitEditing();
      // this.textbox = null;
    },
    // 回显线段
    showStick(data) {
      const { Xaxis1, Xaxis2, Yaxis1, Yaxis2 } = data;
      const line = this.makeLine([Xaxis1, Yaxis1, Xaxis2, Yaxis2]);
      const line1 = this.makeLine([Xaxis2, Yaxis2]);
      this.canvas.add(line, line1);
      const circle = this.makeCircle(
        line.get("x1"),
        line.get("y1"),
        null,
        line
      );
      const circle1 = this.makeCircle(
        line.get("x2"),
        line.get("y2"),
        line,
        line1
      );
      this.canvas.add(circle, circle1);
    },
    // 绘制线段
    drawStick() {
      const { x, y } = this.mouseFrom;
      const line = this.makeLine([x, y, x, y + 70]);
      const line1 = this.makeLine([x, y + 70]);
      line.moveTo(3);
      line1.moveTo(3);
      this.canvas.add(line, line1);
      const circle = this.makeCircle(
        line.get("x1"),
        line.get("y1"),
        null,
        line
      );
      const circle1 = this.makeCircle(
        line.get("x2"),
        line.get("y2"),
        line,
        line1
      );
      circle.moveTo(12);
      circle1.moveTo(12);
      this.canvas.add(circle, circle1);
    },
    // 绘制线段圆点
    makeCircle(left, top, line1, line2) {
      const c = new fabric.Circle({
        left: left,
        top: top,
        currentLeft: left,
        currentTop: top,
        strokeWidth: 1,
        radius: 6,
        fill: "#ff3",
        stroke: "#00d700",
        originX: "center",
        originY: "center",
        typeStick: `stick${this.stickIndex}`,
        type: "stick",
      });
      c.hasControls = c.hasBorders = false;

      c.line1 = line1;
      c.line2 = line2;
      return c;
    },
    // 绘制线段-线段
    makeLine(coords) {
      return new fabric.Line(coords, {
        fill: "red",
        stroke: "red",
        strokeWidth: 2,
        // selectable: false,
        // evented: false,
        originX: "center",
        originY: "center",
        typeStick: `stick${this.stickIndex}`,
        type: "stick",
      });
    },

    /**
     * @description: 返回元素文字
     * @param {type}
     * @return {type}
     */
    tagText(tag, id, left, top, x = 38, y = 25, angle) {
      const text = new fabric.Text(tag, {
        fontSize: 8,
        // left: left,
        // top: top,
        markId: id,
        fill: "#fff",
        originX: "center",
        originY: "center",
      });
      if (angle !== 0) {
        text.set({
          angle: -angle,
        });
      }
      text.moveTo(5);
      return text;
    },

    // 回显示图片
    reshowImage(data) {
      const { Xaxis, Yaxis, ImageName, ResourceType } = data;
      const url = this.imgList[ResourceType === null ? 0 : ResourceType].url;
      // 生成唯一标识
      const id = util.generateId();
      fabric.Image.fromURL(url, (img) => {
        img.set({
          left: Xaxis,
          top: Yaxis,
          drawType: "icon",
          typeName: ImageName,
          typeIndex: ResourceType,
          markId: id,
          originX: "center",
          originY: "center",
          hasControls: false,
          currentLeft: Xaxis,
          currentTop: Yaxis,
          scaleX: 1,
          scaleY: 1,
        }).scale(0.3);

          this.canvas.add(img);
          // 设置层级
          img.moveTo(1);
          // 更新miniMap
          this.updateMiniMap();
          this.canvas.renderAll();
          this.initPic = true;
          if (this.initPic && this.initSuccess) {
            this.updateCanvasState();
          }
        }, { crossOrigin: "Anonymous", type: ResourceType});
    },

    /**
     * 添加电网图片
     * @param item
     * @param index
     */
    addLayer(item, index) {
      this.clickIndex += 1;
      // 生成唯一标识
      const id = util.generateId();
      const { width, height } = this.canvas;
      let top = 60 * this.valueIndex;
      let left = 60 * this.clickIndex;

      if (this.clickIndex % 15 === 0) {
        this.valueIndex += 1;
        this.clickIndex = 0;
        this.clickIndex += 1;
        top = 60 * this.valueIndex;
        left = 60 * this.clickIndex;
      }
      const marginLeft = left < width ? left : width - 30;
      const marginTop = top < height ? top : top - 30;
      fabric.Image.fromURL(
        item.url,
        (img) => {
          img.set({
              left: marginLeft,
              top: marginTop,
              drawType: "icon",
              typeName: item.type,
              typeIndex: index,
              markId: id,
              originX: "center",
              originY: "center",
              hasControls: false,
              currentLeft: marginLeft,
              currentTop: marginTop,
              scaleX: 1,
              scaleY: 1,
            }).scale(0.3);
          this.canvas.add(img);

          // 设置层级
          img.moveTo(1);

          // 更新miniMap
          this.updateMiniMap();
          this.canvas.renderAll();
        },
        { crossOrigin: "Anonymous" }
      );
    },

    /**
     * 绘制光伏面板
     * @param json
     */
    reshowRect(json) {
      const {
        width,
        height,
        Xaxis,
        Yaxis,
        Location,
        Label,
        GroupId,
        HoneybeeOutput,
        PVModuleTypeId,
        HoneybeeMac,
        Angle,
      } = json;
      const label = Label ? Label : HoneybeeMac;
      const macId = HoneybeeMac;
      // 生成唯一标识
      const id = util.generateId();
      const canvasObject = new fabric.Rect({
        width: 44,
        height: 66,
        originX: "center",
        originY: "center",
        fill: "#70a436",
        stroke: "#fff",
        strokeWidth: 1,
        honeybeeOutType: HoneybeeOutput,
        pVModuleTypeId: PVModuleTypeId,
        groupId: GroupId,
        tagName: Location,
        macId: macId,
        type: "string",
        lineWidth: 1,
        markId: id,
        hasControls: false,
        lockRotation: false, // 设置不可旋转
      });
      const text = `  ${Location}\n${label}`;
      const ang = Angle ? Angle : 0;
      const tagText = this.tagText(text, id, Xaxis, Yaxis, 23, 25, ang);
      const group = this.elementGroup(canvasObject, tagText, id, "string", ang, Xaxis, Yaxis);
      this.canvas.add(group);
    },

    /**
     * 绘制逆变器
     * @param json
     */
    reshowReverst(json) {
      const { width, height, Xaxis, Yaxis, Label, Id, Angle } = json;
      const id = util.generateId(); //生成唯一标识
      const canvasObject = new fabric.Rect({
        width: 80,
        height: 50,
        fill: "#339966",
        stroke: "#fff",
        strokeWidth: 1,
        tagName: Label,
        lineWidth: 1,
        markId: id,
        inverterId: Id,
        currentLeft: Xaxis,
        originX: "center",
        originY: "center",
        type: "inverter",
        hasControls: false,
        lockRotation: false,
      });
      const text = `${Label}`;
      const ang = Angle ? Angle : 0;
      const tagText = this.tagText(text, id, Xaxis, Yaxis, 40, 28, ang);

      const group = this.elementGroup(
        canvasObject,
        tagText,
        id,
        "inverter",
        ang,
        Xaxis,
        Yaxis
      );
      this.canvas.add(group);
    },

    /**
     * 设置旋转时文字不动
     * @param e
     */
    setRotate(e) {
      const { target } = e;
      if (target.type && target.type === "group") {
        const { angle, _objects } = target;
        _objects[1].set("angle", -angle).setCoords();

        // _objects[1].addWithUpdate();
        this.canvas.renderAll();
      }
    },

    // 超出图片之外无法绘制提示
    overCanvasArea(pointer, isShow = true) {
      let boolean = true;
      const { width, height } = this.canvas;
      const { x, y } = pointer;
      if (x < 0 || y < 0 || x > width || y > height) {
        boolean = false;
      }
      return boolean;
    },

    // 设置元素移动
    setMove(e) {
      const { target } = e;

      target.setCoords();
      this.limitArea(e);

      this.updateMiniMap();

      // 拖动线段
      if (target.type && target.type === "stick") {
        const p = e.target;
        p.line1 && p.line1.set({ x2: p.left, y2: p.top });
        p.line2 && p.line2.set({ x1: p.left, y1: p.top });
        //检查是否发生碰撞
        const items = this.canvas
          .getObjects()
          .filter((v) => v !== target && v.type && v.type === "group");
        let hit = false;
        for (let i = 0; i < items.length; i++) {
          if (target.intersectsWithObject(items[i])) {
            target.set("fill", "red");
            hit = true;
          } else {
            if (!hit) {
              target.set("fill", "#ff3");
            }
          }
        }
        this.canvas.renderAll();
      }
      // 拖动图片时记录位置
      if (target.drawType && target.drawType === "icon") {
        const { left, top } = target;
        target.set({
          currentLeft: left,
          currentTop: top,
        });
        this.canvas.renderAll();
      }
      // 拖动矩形时记录位置
      if (target.type && target.type === "group") {
        const { left, top } = target;
        target.set({
          currentLeft: left,
          currentTop: top,
          originX: "center",
          originY: "center",
        });
        this.canvas.renderAll();
      }

      // 拖动矩形
      if (target.type && target.type === "group") {
        //检查是否发生碰撞
        const items = this.canvas
          .getObjects()
          .filter((v) => v !== target && v.type && v.type === "stick");
        let hit = false;
        for (let i = 0; i < items.length; i++) {
          const obj = items[i];

          if (target.intersectsWithObject(items[i])) {
            obj.set("fill", "red");

            hit = true;
          } else {
            if (!hit) {
              obj.set("fill", "#ff3");
            }
          }
          obj.setCoords();
        }
        this.canvas.renderAll();
      }
    },
    // 限制选中元素移出图片外
    limitArea(e) {
      const { width, height } = this.canvas;
      const obj = e.target;
      obj.setCoords();
      const { tl, tr, br, bl } = obj.aCoords;
      const maxW = Math.abs(tr.x - tl.x);
      const maxH = Math.abs(br.y - tr.y);
      // 上 左
      if (obj.left <= 0 || obj.top <= 0) {
        const maxT = Math.min(obj.top, height - maxH);
        obj.top = Math.max(maxT, 0);
        const maxL = Math.min(obj.left, width - maxW);
        obj.left = Math.max(maxL, 0);
        return;
      }

      // 下 右
      if (obj.top >= height - maxH || obj.left >= width - maxW) {
        obj.top = Math.min(obj.top, height - maxH);
        obj.left = Math.min(obj.left, width - maxW);
        return;
      }
    },
    /**
     * 初始化miniMap
     */
    initMiniMap() {
      const canvas = this.createCanvasEl1();
      if (!this.miniMap) {
        return;
      }
      const backgroundImage = new fabric.Image(canvas);
      backgroundImage.scaleX = 1;
      backgroundImage.scaleY = 1;
      this.miniMap.centerObject(backgroundImage);
      this.miniMap.backgroundColor = "white";
      this.miniMap.backgroundImage = backgroundImage;
      this.miniMap.renderAll();
    },
    // 更新miniMap
    updateMiniMap() {
      const canvas = this.createCanvasEl();
      this.miniMap.backgroundImage._element = canvas;
      this.miniMap.requestRenderAll();
    },
    // 创建画布背景
    createCanvasEl() {
      const { width, height } = this.canvas;
      const designSize = {
        width: width,
        height: height,
      };
      const originalVPT = this.canvas.viewportTransform;
      const designRatio = fabric.util.findScaleToFit(designSize, this.canvas);

      const minimapRatio = fabric.util.findScaleToFit(
        this.canvas,
        this.miniMap
      );

      const scaling = this.miniMap.getRetinaScaling();

      const finalWidth = designSize.width * designRatio;
      const finalHeight = designSize.height * designRatio;
      this.canvas.viewportTransform = [
        designRatio,//水平缩放（x轴方向）
        0,//水平倾斜（x轴方向）
        0,//垂直倾斜（y轴方向）
        designRatio,//垂直缩放（y轴方向）
        (this.canvas.getWidth() - finalWidth) / 2,//水平移动（x轴方向）
        (this.canvas.getHeight() - finalHeight) / 2,//垂直移动（y轴方向）
      ];
      const canvas = this.canvas.toCanvasElement(minimapRatio);
      this.canvas.viewportTransform = originalVPT;
      return canvas;
    },
    createCanvasEl1() {
      const minimapRatio = fabric.util.findScaleToFit(
        this.canvas,
        this.miniMap
      );
      const canvas = this.canvas.toCanvasElement(minimapRatio);
      return canvas;
    },

    keyDown(e) {
      // 每一步移动的像素距离
      const step = 2;
      // 选中元素
      if (this.selectRect) {
        if(e.key === "ArrowUp") {
          e.preventDefault();
          this.selectRect.set("top", this.selectRect.get("top") - step)
        } else if(e.key === "ArrowDown") {
          e.preventDefault();
          this.selectRect.set("top", this.selectRect.get("top") + step)
        } else if(e.key === "ArrowLeft") {
          e.preventDefault();
          this.selectRect.set("left", this.selectRect.get("left") - step)
        } else if(e.key === "ArrowRight") {
          e.preventDefault();
          this.selectRect.set("left", this.selectRect.get("left") + step)
        }
        this.rectCoord[3].value = this.selectRect.get("left");
        this.rectCoord[4].value = this.selectRect.get("top");
      } else if(this.isGrouoShow) {
        for (const eElement of this.isGrouoShow) {
          if(e.key === "ArrowUp") {
            e.preventDefault();
            eElement.set("top", eElement.get("top") - step)
          }
          if(e.key === "ArrowDown") {
            e.preventDefault();
            eElement.set("top", eElement.get("top") + step)
          }
          if(e.key === "ArrowLeft") {
            e.preventDefault();
            eElement.set("left", eElement.get("left") - step)
          }
          if(e.key === "ArrowRight") {
            e.preventDefault();
            eElement.set("left", eElement.get("left") + step)
          }
        }
      }
      this.canvas.renderAll();
    },

    /**
     * 更新canvas状态
     */
    updateCanvasState() {
      if (!this.isLoadCanvas) {
        const all = this.canvas.getObjects();
        const string = all.filter((v) => v.typeStick && v.typeStick === "string"); //组串
        let stringData = [];
        if (string.length > 0) {
          // 组串数据
          string.forEach((v) => {
            const {
              macId,
              tagName,
              groupId,
              honeybeeOutType,
              pVModuleTypeId,
              currentLeft,
              currentTop,
            } = v._objects[0];
            const json = {
              GroupId: groupId,
              HoneybeeMac: macId,
              Location: tagName,
              HoneybeeOutput: honeybeeOutType,
              PVModuleTypeId: pVModuleTypeId,
              Xaxis: v.left,
              Yaxis: v.top,
              Angle: v.angle ? (v.angle < 0 ? 180 + v.angle : (v.angle > 180 ? 360 - v.angle : v.angle)) : 0,
            };
            stringData.push(json);
          });
        }

        // 逆变器
        let inverterData = [];
        const inverter = all.filter(
            (v) =>
                v.typeStick &&
                v.typeStick === "inverter" &&
                v._objects &&
                v._objects[0].type === "inverter"
        );
        if (inverter.length > 0) {
          // 逆变器数据
          inverter.forEach((v) => {
            const { aCoords, tagName, inverterId, currentTop, currentLeft } =
                v._objects[0];
            const json = {
              Label: tagName,
              Id: inverterId,
              // Xaxis: v.currentLeft ? v.currentLeft : currentLeft,
              Xaxis: v.left,
              // Yaxis: v.currentTop ? v.currentTop : currentTop,
              Yaxis: v.top,
              Angle: v.angle ? (v.angle<0?180+v.angle:(v.angle>180?360-v.angle: v.angle)) : 0,
            };
            inverterData.push(json);
          });
        }

        const icons = all.filter((v) => v.drawType && v.drawType === "icon");
        const iconImage = icons.map((v) => {
          return {
            Xaxis: v.currentLeft,
            Yaxis: v.currentTop,
            ImageName: v.typeName,
            ResourceType: v.typeIndex,
          };
        });

        const historyCanvasState = {
          iconImage,
          stringData,
          inverterData
        }
        const canvasAsJson = JSON.stringify(historyCanvasState);
        this.canvasState.push(canvasAsJson);
        this.stateIndex = this.canvasState.length - 1;
      } else {
        this.isLoadCanvas = false;
      }
    },

    /**
     * 撤销恢复canvas
     * @param index
     */
    historyState(flag) {

      if ("Undo" == flag && this.canUndo) {
        this.stateIndex--;
      } else if ("Redo" == flag && this.canRedo) {
        this.stateIndex++;
      } else {
        return;
      }
      if (this.canUndo || this.canRedo) {
        this.isLoadCanvas = true;
        const inverterData  = JSON.parse(this.canvasState[this.stateIndex]).inverterData;
        const stringData  = JSON.parse(this.canvasState[this.stateIndex]).stringData;
        const iconImage  = JSON.parse(this.canvasState[this.stateIndex]).iconImage;

        if (inverterData.length > 0 || stringData.length > 0) {
          this.canvas.clear();
        }
        // 逆变器
        inverterData.forEach((v) => {
          this.reshowReverst(v);
        });

        // 光伏面板
        stringData.forEach((v) => {
          this.reshowRect(v);
        });

        if (iconImage) {
          iconImage.forEach((v) => {
            this.reshowImage(v);
          });
        }

        // 更新miniMap
        this.updateMiniMap();
        this.canvas.renderAll();
      }
    },
  },
  created() {
    this.getTreeData();

  },
  mounted() {
    document.querySelector(".defaultColor").innerHTML = this.$t("design.defaultColor");
    document.querySelectorAll(".bd h3")[0].innerHTML = this.$t("design.themeColor");
    document.querySelectorAll(".bd h3")[1].innerHTML = this.$t("design.standardColor");
    document.querySelectorAll(".bd h3")[2].innerHTML = this.$t("design.moreColors");

    // 渲染miniMap
    this.miniMap = new fabric.Canvas("minimap", {
      containerClass: "minimap",//类名
      selection: false,
    });

    if (!this.rect) {
      // 矩形
      this.rect = new fabric.Rect({
        left: 0,
        top: 0,
        width: 80,
        height: 80,
        fill: "rgba(190, 190, 190, 0.2)",
        hasBorders: false,
        hasControls: false
      });
      this.miniMap.add(this.rect);
    }

    this.miniMap.on("object:moving", (options) => {

      const point = new fabric.Point(
          0 - options.e.movementX * this.canvas.width / this.miniMap.width,
          0 - options.e.movementY * this.canvas.height / this.miniMap.height
      );
      this.canvas.relativePan(point);
      this.canvas.renderAll();
    });

    // 渲染画布
    this.canvas = new fabric.Canvas("canvas", {
      preserveObjectStacking: true, // 为true时顶置选中元素
      isDrawingMode: false,
      // hoverCursor: "default",
      selectionFullyContained: true, // 框选时全包含选中的元素
      selectionLineWidth: 1,
      selection: true,
      // selectable: false,//错误用法
      // hasControls: false,//错误用法
      imageSmoothingEnabled: false, //错误用法，抗锯齿，一般默认关闭
    });

    // 事件绑定
    // 鼠标移动
    this.canvas.on("mouse:move", this.mouseMove);
    // 鼠标移出画布
    this.canvas.on("mouse:out", this.moveOut);
    // 鼠标移入画布
    this.canvas.on("mouse:over", this.mouseOver);
    // 鼠标按下松开
    this.canvas.on("mouse:down", this.mouseDown);
    this.canvas.on("mouse:up", this.mouseUp);
    // 选中元素旋转
    this.canvas.on("object:rotating", this.setRotate);
    // 元素移动
    this.canvas.on("object:moving", this.setMove);
    // 元素添加
    this.canvas.on("object:added", (e) => {
      if (!this.isLoadCanvas && this.initSuccess && this.initPic) {
        this.updateCanvasState();
      }
    });
    // 元素删除
    this.canvas.on("object:removed ", (e) => {
      if (!this.isLoadCanvas && this.initSuccess && this.initPic) {
        this.updateCanvasState();
      }
    });
    // 变换时更新miniMap
    this.canvas.on("object:modified", (e) => {
      if (e.target) {
        this.updateCanvasState();
        const { target } = e;
        if (target._objects && target._objects.length > 0) {
          const group = target._objects.filter(
            (v) => v.type && v.type === "group"
          );
          const icon = target._objects.filter(
            (v) => v.drawType && v.drawType === "icon"
          );
          const { translateX, translateY } = e.target;
          if (icon.length > 0) {
            for (let i = 0; i < icon.length; i++) {
              const obj = icon[i];
              const { left, top } = obj;
              obj.set({
                currentLeft: translateX + left,
                currentTop: translateY + top,
              });

              this.canvas.renderAll();
            }
          }
          if (group.length > 0) {
            for (let i = 0; i < group.length; i++) {
              const obj = group[i];
              const { left, top } = obj;
              obj.set({
                currentLeft: translateX + left,
                currentTop: translateY + top,
              });
              obj.setCoords();
              obj.moveTo(0);
              this.canvas.renderAll();
            }
          }
        }
      }
      this.updateMiniMap();
    });
    // 选择多个元素时设置最外包裹框属性
    this.canvas.on("selection:created", (e) => {
      if (e.selected && e.selected.length > 1) {
        const target = e.target;
        this.isGrouoShow = e.selected;
        target.set({
          hasControls: false,
        });
      }
    });

    this.canvas.on("selection:cleared", (e) => {
      if (e.deselected && e.deselected.length > 1) {
        this.isGrouoShow = [];
      }
      this.canvas.discardActiveObject();
      this.canvas.requestRenderAll();
    });

    // 监听键盘事件
    window.addEventListener('keydown', this.keyDown);

    // 监听鼠标滚轮事件
    this.canvas.on('mouse:wheel', options => {
      let delta = options.e.deltaY
      if (this.rect.get("top") <= 0 && delta < 0) {

      } else if (this.rect.get("top") >= (this.miniMap.height - this.rect.height) && delta > 0) {

      } else {
        this.rect.set("top", this.rect.get("top") + delta / (this.canvas.height / this.miniMap.height));
        this.miniMap.renderAll();
      }
    });
    this.canvas.renderAll();
  },
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";

::v-deep .x-scroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px #409EFF;
  background: #409EFF;
}

::v-deep .y-scroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px #409EFF;
  background: #409EFF;
}

.disabled {
  cursor:not-allowed;
  opacity: 0.6;
}
</style>
