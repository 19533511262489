<template>
  <div class="login-container">
    <lang class="lang" ></lang>
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form fadeIn"
      auto-complete="off"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">{{$t('login.title')}}</h3>
      </div>

      <el-form-item prop="username">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          ref="username"
          v-model="loginForm.username"
          placeholder="Username"
          name="username"
          auto-complete="off"
          type="text"
          tabindex="1"
        />
      </el-form-item>

      <el-form-item prop="password">
        <span class="svg-container">
          <svg-icon icon-class="password" />
        </span>
        <el-input
          :key="passwordType"
          ref="password"
          v-model="loginForm.password"
          :type="passwordType"
          placeholder="Password"
          name="password"
          auto-complete="off"
          tabindex="2"
          @keyup.enter.native="handleLogin()"
        />
        <span class="show-pwd" @click="showPwd">
          <svg-icon
            :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
          />
        </span>
      </el-form-item>
      <el-button
        :loading="loading"
        type="success"
        style="width:100%;margin-bottom:10px; height: 35px; font-size: 14px !important;"
        @click.native.prevent="handleLogin(1)"
        >{{$t('login.login')}}</el-button>
      <el-button
        :loading="demoLoading"
        class="warning"
        style="width:100%;margin-bottom:10px; height: 35px; font-size: 14px !important;"
        @click="handleLogin(2)"
        >{{$t('login.demo')}}</el-button>

      <div class="tips">
        <span style="margin-right:20px;" @click="handleRegister">{{$t('login.register')}}</span>
        <span @click="forgetPassword">{{$t('login.Forgot')}}</span>
      </div>

      <div class="scodw">
        <div>
          <p>{{$t('login.text')}}</p>
        </div>
        <div style="display: inline-block; cursor: pointer;" @click="openUrl(appUrl)">
          <vue-qr :logoSrc="logoImg" :text="appUrl" :size="83" :margin="3"></vue-qr>
          <p style="text-align: center;">{{$t('login.appUrl')}}</p>
        </div>
        <div style="display: inline-block; cursor: pointer;" @click="openUrl(googleAppUrl)">
          <vue-qr :logoSrc="logoImg" :text="googleAppUrl" :size="83" :margin="3"></vue-qr>
          <p style="text-align: center;">{{$t('login.googleAppUrl')}}</p>
        </div>
        <div style="display: inline-block; cursor: pointer;" @click="openUrl(appleAppUrl)">
          <vue-qr :logoSrc="logoImg" :text="appleAppUrl" :size="83" :margin="3"></vue-qr>
          <p style="text-align: center;">{{$t('login.appleAppUrl')}}</p>
        </div>
      </div>

      <div style="padding: 2px; margin: 15px 0 5px 0; border: 1px solid black; text-align: center;">
        <label>{{$t('login.technicalSupport')}}</label>
      </div>
      <div class="scodw">
        <div style="display: inline-block; cursor: pointer;" >
          <!-- <a href="https://api.whatsapp.com/send?phone="> -->
            <img :src="require('@/assets/login/whatupqr.jpg')" width="103"/>
            <p style="text-align: center;">{{$t('login.whatsApp')}}</p>
          <!-- </a> -->
        </div>
        <div style="display: inline-block; cursor: pointer;" >
          <img :src="require('@/assets/login/wechartqr.jpg')" width="103"/>
          <p style="text-align: center;">{{$t('login.wechat')}}</p>
        </div>
      </div>

      <div class="tips">
        <el-link style="margin-top: 10px;" href="/doc/Monitoring_platform_usage_instructions.pdf" target="_blank">
          {{ $t('login.monitoringPlatformUsageInstructions') }}
        </el-link>
      </div>

    </el-form>
    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.visible"
      style="min-width:400px"
    >
      <components :is="componentName" :props="dialog" />
    </el-dialog>

    <canvas id="background"></canvas>
  </div>

</template>

<script>
import {backgroundShow1} from '@/views/login/background/background-vue1.js';
import { validUsername } from "@/utils/validate";
import password from "./components/password";
import vueQr from "vue-qr";

export default {
  name: "Login",
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("The password can not be less than 6 digits"));
      } else {
        callback();
      }
    };
    return {
      dialog: {
        title: "",
        visible: false
      },
      componentName: "password",
      loginForm: {
        username: "", //eric
        password: "" //eric123456
      },
      loginRules: {
        username: validUsername,
        password: [
          { required: true, trigger: "blur", validator: validatePassword }
        ]
      },
      loading: false,
      demoLoading: false,
      passwordType: "password",
      redirect: undefined,
      logoImg: require("@/assets/login/logo.png"),
      appUrl:"https://a.gnetek.com/webapi/downresource/download?fileId=com.teddy.app",
      googleAppUrl:"https://play.google.com/store/apps/details?id=com.teddy.app",
      appleAppUrl:"https://apps.apple.com/app/id1034215421"
    };
  },
  components: {
    password,
    vueQr
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    /**
     * @description: 忘记密码操作
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */

    forgetPassword() {
      this.dialog.visible = true;
    },
    /**
     * @description: 跳转注册
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */

    handleRegister() {
      this.$router.push("/register");
    },

    /**
     * @description:
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */

    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    /**
     * @description: 用户登录
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */

    handleLogin(val) {
     if(val==2){
      this.loginForm.username='demo';
      this.loginForm.password='123456';
     }

      this.$refs.loginForm.validate(async valid => {
        try {
          if (valid) {
            this.loading = true;
            this.$store.dispatch("user/login", this.loginForm).then(()=>{
              this.loading = false;
              this.$router.push({ path: "/stationReport" || "/" });
            }).catch(()=>{
              this.loading = false;
            });
            /* setTimeout(() => {
              this.$router.push({ path: "/stationReport" || "/" }).catch(error => {
                console.log(error);
              });
            }, 300); */
          } else {
            console.log("error submit!!");
            return false;
          }
        } catch (error) {
          this.loading = false;
        }
      });
    },

    openUrl(url){
      window.open(url,'_blank')
    }
  },
  mounted() {
    // backgroundShow();
    backgroundShow1();
  }
};
</script>

<style lang="scss">
@import "./style/index";
</style>
<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  // min-width: 447px;
  // min-height: 665px;
  width: 100%;
  height: 100%;
  .login-form {
    position: absolute;
    width: 512px;
    padding: 25px 40px;
    margin: -360px 0 0 -256px;
    max-width: 100%;
    left: 50%;
    top: 50%;
    overflow: hidden;
    background: rgba(181, 212, 147, 0.6);
    border-radius: 10px;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 768px){
      width: 56%;
      padding: 4% 2%;
      margin: -50% 0 0 -28%;
    }

    @media screen and (max-width: 597px){
      width: 100%;
      padding: 4% 2%;
      margin: -80% 0 0 -50%;
    }
  }
  .lang {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 999999;
  }
  .tips {
    text-align: right;
    width: 100%;
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
    span, a {
      cursor: pointer;
      color: #fff;
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }
  .title-container {
    position: relative;
    .title {
      font-family: Source Han Sans CN;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 36px;
      color: #fff;
      margin-bottom: 10px;
      text-align: center;
      font-weight: bold;
    }
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .scodw {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    img {
      float: left;
      // margin-right: 20px;
      padding: 0 10px;
    }
    p {
      font-family: Source Han Sans CN;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      text-align: left;
      line-height: 19px;
      color: #FFFAFA;
    }
  }
  #background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -100;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(ellipse farthest-corner at center top, #5c7e69 0%, #013d18 100%);
  }
  /*从上到下进入*/
  .fadeIn {
      -webkit-animation: fadeInDown 1.5s;
      animation: fadeInDown 1.5s;
  }
  @keyframes fadeInDown {
      0% {
          -webkit-transform: translate3d(0, -20%, 0);
          -webkit-transform: translate3d(0, -20%, 0);
          transform: translate3d(0, -20%, 0);
          transform: translate3d(0, -20%, 0);
          opacity: 0;
      }
      100% {
          -webkit-transform: none;
          transform: none;
          opacity: 1;
      }
  }
  @-webkit-keyframes fadeInDown {
      0% {
          -webkit-transform: translate3d(0, -20%, 0);
          opacity: 0;
      }
      100% {
          -webkit-transform: none;
          opacity: 1;
      }
  }
}
</style>
