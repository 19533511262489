var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "TopBar" }, [
    _c("div", { staticClass: "topbar-left" }, [
      _c("span", { staticClass: "left-front" }, [
        _vm._v(_vm._s(_vm.$t("register.title")) + "｜"),
      ]),
      _c("div", { staticClass: "left-back" }, [
        _vm._v(_vm._s(_vm.stationName)),
        _c("br"),
        _c("span", { staticClass: "left-addr" }, [
          _vm._v(_vm._s(_vm.stationAddress)),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "topbar-right" },
      [
        _c(
          "div",
          { staticClass: "right-lang" },
          [_c("lang", { staticClass: "lang" })],
          1
        ),
        _c("p", { staticClass: "right-line" }),
        _c("AvatarName"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }