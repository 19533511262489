<!--
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-11-14 11:31:24
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-05-23 22:37:18
-->
<template>
   <div class="childControl">
     <conrtl-box :idList='idList' :child='true'/>
      <GnGrid
      ref="grid"
      :url="getListsAPI"
      :params="gridOptions"
      :methods="'get'"
      :defaultHeight='500'
      :tableStyle="tableStyle"
      @select-change='selectAll'
      :render-content="gridRenderContent"
      @select="handleSelectionChange"
      class="UserRoles-grid"
    />

   </div>
</template>

<script>
import conrtlBox from './conrtlBox.vue'
/**
  * @author: JIXUYU
  * @since: 2021-11-14
  * @router('/childControl')
  */
export default {
  name:"childControl",
  components : {conrtlBox},
  props : {},
  data () {
    return {
     getListsAPI:'/status/honeybeeStatusList',
     gridOptions:{},
     multipleSelection:[],
     tableStyle:{
      width:"100%",
      minHeight:"400px"
     },
     idList:''
    }
  },

  methods : {
     /**
      * @description: 列表数据渲染
      * @param {*}
      * @return {*}
      * @author: JIXUYU
      */
     gridRenderContent() {
      return [
        { label:this.$t('control.selectAll'), type: 'selection' },
        { label: "MACID", render:(scope)=>(
         <span>{scope.row.id?scope.row.id:scope.row.labelName}</span>
        )
        },
        { label: this.$t('control.SummaryId'), prop: "beehivemacid" },
           {
          label: this.$t('control.status'),
          render: scope => (
            <span class={scope.row.onStatus === 0 ? "status" : "statusActive"}>
              {scope.row.onStatusName}
            </span>
          )
        },
        { label:this.$t('control.updateTime'), prop: "lastconnectedtime" }
      ];
    },

    /**
     * @description: 数据筛选
     * @param {*} selection
     * @return {*}
     * @author: JIXUYU
     */
    handleSelectionChange(selection,row){
      // 当前列表数据内容
       let tableData=this.$refs.grid.tableData
       // true 勾选状态 false 取消状态
       let selected = selection.length && selection.indexOf(row) !== -1
        //选中状态
       if(selected){
        // 选中父节点
         if(row.id==null){
            tableData.forEach((el)=>{
            if(row.groupId===el.groupId){
              //子节点之前已被勾选 需剔除
                this.$refs.grid.toggleRowSelection(el);
                this.multipleSelection.push(el.id)
            }
           })
           this.$refs.grid.toggleRowSelection(row);
           //勾选的数据id集合
            this.multipleSelection=[...new Set(this.multipleSelection.filter(item => item!=null ))]
           this.idList=this.multipleSelection.join(',')
         }else{
          //选中子节点
          console.log("row=", row);
          this.multipleSelection.push(row.id);
          this.multipleSelection=[...new Set(this.multipleSelection.filter(item => item!=null ))]
          this.idList=this.multipleSelection.join(',')
         }
       }else{
        // 取消状态
         if(row.id==null){
         //父节点取消
            tableData.forEach((el)=>{
               if(row.groupId===el.groupId){
                  this.$refs.grid.clearSelection(el);
                  this.multipleSelection=[...new Set(this.multipleSelection.filter(item => item!=el.id ))]
                  this.idList=this.multipleSelection.join(',')
               }
            })
         }else{
          //子节点取消
          this.multipleSelection=[...new Set(this.multipleSelection.filter(item => item!=row.id ))]
          this.idList=this.multipleSelection.join(',')
         }
       }
    },
    /**
     * @description: 全选
     * @param {*} selection
     * @return {*}
     * @author: JIXUYU
     */
    selectAll(selection){
     this.multipleSelection=[]
     this.idList=''
     this.multipleSelection=this.$refs.grid.getSelectedIds()
     this.multipleSelection =this.multipleSelection.filter(item => item!=null )
     this.idList=this.multipleSelection.join(',')

    }
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
@import '../style/index';
</style>
