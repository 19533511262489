import { login, logout, getInfo } from "@/api/user";
import { resetRouter, constantRoutes } from "@/router";
import { getRouterList } from '@/api/user'
import * as util from "@/utils/index";
import Fingerprint2 from 'fingerprintjs2';
const TokenKey = "plant-token";
const getDefaultState = () => {
  Fingerprint2.get(function(components) {
    const values = components.map(function(component,index) {
      if (index === 0) { //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
        return component.value.replace(/\bNetType\/\w+\b/, '')
      }
      return component.value
    })
    // 生成最终id murmur
    const murmur = Fingerprint2.x64hash128(values.join(''), 31);
    mutations.SET_MMURMUR(state, murmur);
  });

  const userInfo = util.readSession("userInfo")

 return {
  token: util.readSession(TokenKey),
  name: "",
  avatar: "",
  roles: [], // 角色权限控制按钮显示
  menus: [], // 菜单权限
  stationName: userInfo?userInfo.stationName:'',
  stationAddress: userInfo?userInfo.country:'',
  murmur: '',//浏览器唯一标识
 };
};

const state = getDefaultState();

const getters= {
  murmur: state => state.murmur,
};

const mutations = {
 RESET_STATE: state => {
  Object.assign(state, getDefaultState());
 },
 SET_TOKEN: (state, token) => {
  state.token = token;
 },
 SET_NAME: (state, name) => {
  state.name = name;
 },
 SET_AVATAR: (state, avatar) => {
  state.avatar = avatar;
 },
 SET_ROLES: (state, roles) => {
  state.roles = roles // 角色权限
 },
 SET_MENUS: (state, menus) => {
  state.menus = menus // 菜单权限
 },
 SET_STATION: (state, stationName, stationAddress) => {
  state.stationName = stationName // 电站切换
  state.stationAddress = stationAddress
 },
 SET_STATION_ADDRESS: (state, stationAddress) => {
  state.stationAddress = stationAddress
 },
 SET_MMURMUR: (state, murmur)=> {
   state.murmur = murmur; //设置唯一标识
 }
};

const actions = {
 /**
  * @description: 电站切换
  * @param {*} commit
  * @param {*} stationName
  * @return {*}
  * @author: JIXUYU
  */
 changeStation({ commit }, stationName) {
  commit("SET_STATION", stationName);
},
changeStationAddress({ commit }, stationAddress) {
  commit("SET_STATION_ADDRESS", stationAddress);
},
 // user login
 login ({ commit }, userInfo) {
  const { username, password } = userInfo;
  const murmur = getters.murmur(state);
  return new Promise((resolve, reject) => {
   // const data = 'x-token'
   // commit("SET_TOKEN", data);
   // util.writeSession(TokenKey, data);
   // resolve();
   login({ username: username.trim(), password: password, murmur: 'browser:'+murmur })
    .then(response => {
     const { data } = response;
     commit("SET_TOKEN", data);
     util.writeSession(TokenKey, data);
     resolve();
    })
    .catch(error => {
     reject(error);
    });
  });
 },

 // get user info
 getInfo ({ commit, state }) {
  return new Promise((resolve, reject) => {
   // const data = {
   //   avatar:
   //     "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif",
   //   username: "Super Admin"
   // };
   // const { username, avatar } = data;
   // commit("SET_NAME", username);
   // commit("SET_AVATAR", avatar);
   // resolve(data);
   getInfo(state.token)
    .then((response) => {
     const {
      data
     } = response;
     util.writeSession("userInfo", data);
     util.writeSession("stationId", data.stationId);
     console.log("-----------");
     console.log(data);
     commit("SET_STATION", data.stationName);
     let addr = localStorage.getItem('lang')==='zh'?
                util.defaultValue(data.country) +" "+
                util.defaultValue(data.province) +" "+
                util.defaultValue(data.city) +" "+
                util.defaultValue(data.district)
                :
                util.defaultValue(data.district)+" "+
                util.defaultValue(data.city) +" "+
                util.defaultValue(data.province) +" "+
                util.defaultValue(data.country)
     commit("SET_STATION_ADDRESS", addr);
     // util.writeSession("stationName", data.stationName);
     if (!data) {
      return reject("Verification failed, please Login again.");
     }

     const {
      username,
      avatar
     } = data;

     commit("SET_NAME", username);
     commit("SET_AVATAR", avatar);
     resolve(data);
    })
    .catch((error) => {
     reject(error);
    });
  });
 },

 // user logout
 logout ({ commit, state }) {
  return new Promise((resolve, reject) => {
   logout(state.token)
    .then(() => {
     util.removeSession(TokenKey);
     resetRouter();
     commit("RESET_STATE");
     resolve();
    })
    .catch(error => {
     reject(error);
    });
  });
 },
 /**
* @description:  菜单权限资源相关操作
* @param {type}
* @return {type}
* @author: JIXUYU
*/
 setAllRoutes ({ commit }) {
  return new Promise((resolve, reject) => {
   getRouterList()
    .then((res) => {
     const { data } = res
     commit('SET_MENUS', data) // 菜单权限
     resolve(data);
    })
    .catch(error => {
     reject(error);
    });
  });
 },


 // remove token
 resetToken ({ commit }) {
  return new Promise(resolve => {
   util.removeSession(TokenKey);
   commit("RESET_STATE");
   resolve();
  });
 }

};

export default {
 namespaced: true,
 state,
 mutations,
 actions
};
