var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "collectInfo",
          attrs: { model: _vm.ruleForm, "label-width": "82px" },
        },
        [
          _c(
            "div",
            {
              staticClass: "info-label",
              staticStyle: { "margin-bottom": "20px" },
            },
            [
              _c("p", { staticClass: "label-title" }, [
                _vm._v(_vm._s(this.$t("config.SummarySet"))),
              ]),
              _c("p", { staticClass: "label-icon" }, [
                _c("span", {
                  staticClass: "iconfont icon-add_circle icon-plus",
                  on: { click: _vm.addForm },
                }),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "block", staticStyle: { "margin-bottom": "20px" } },
            [
              _c("el-image", {
                ref: "myImg",
                staticStyle: {
                  width: "100px",
                  height: "100px",
                  cursor: "pointer",
                },
                attrs: {
                  src: require("@/assets/dev/3_01.jpg"),
                  "preview-src-list": [
                    require("@/assets/dev/3_01.jpg"),
                    require("@/assets/dev/3_02.jpg"),
                  ],
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleClickItem($event)
                  },
                },
              }),
              _c("el-image", {
                ref: "myImg1",
                staticStyle: {
                  width: "100px",
                  height: "100px",
                  "margin-left": "10px",
                  cursor: "pointer",
                },
                attrs: {
                  src: require("@/assets/dev/3_02.jpg"),
                  "preview-src-list": [
                    require("@/assets/dev/3_02.jpg"),
                    require("@/assets/dev/3_01.jpg"),
                  ],
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleClickItem($event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._l(_vm.ruleForm.formList, function (item, index) {
            return _c(
              "el-form-item",
              {
                key: index,
                attrs: {
                  label: "Mac ID：",
                  prop: "formList." + index + ".idName",
                  rules: [
                    {
                      required: true,
                      message: _vm.$t("config.UnitId"),
                      trigger: ["blur"],
                    },
                    {
                      min: 1,
                      max: 8,
                      message: _vm.$t("config.PCode"),
                      trigger: "change",
                    },
                  ],
                },
              },
              [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      effect: "light",
                      content: _vm.$t("config.notExist"),
                      placement: "right",
                      disabled: item.show,
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "info-input",
                      attrs: { placeholder: _vm.$t("config.PMacIdImage") },
                      on: {
                        blur: function ($event) {
                          return _vm.testMac(
                            item.idName,
                            item,
                            "formList." + index + ".idName",
                            index
                          )
                        },
                      },
                      model: {
                        value: item.idName,
                        callback: function ($$v) {
                          _vm.$set(item, "idName", $$v)
                        },
                        expression: "item.idName",
                      },
                    }),
                  ],
                  1
                ),
                _c("span", {
                  staticClass: "iconfont icon-delete input-red",
                  on: {
                    click: function ($event) {
                      return _vm.deleteForm(item, index)
                    },
                  },
                }),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: _vm.$t("config.clickMe"),
                      placement: "right",
                      effect: "light",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "iconfont el-icon-question",
                      staticStyle: {
                        "margin-left": "5px",
                        color: "cornflowerblue",
                        cursor: "pointer",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.question($event)
                        },
                      },
                    }),
                  ]
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c("div", { staticClass: "info-bottom" }, [
        _c("p", { staticClass: "info-btn", on: { click: _vm.saveInfo } }, [
          _vm._v(_vm._s(this.$t("config.save"))),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }