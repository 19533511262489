// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/design/control-off.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/design/control-on.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".conrtlBox[data-v-6c613bd6] {\n  padding: 0 20%;\n}\n.conrtlBox .conrtlChlid[data-v-6c613bd6] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n.conrtlBox .left[data-v-6c613bd6],\n.conrtlBox .right[data-v-6c613bd6] {\n  background-size: 200px 200px;\n  background-repeat: no-repeat;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  position: relative;\n  width: 200px;\n  height: 200px;\n  cursor: pointer;\n}\n.conrtlBox .left img[data-v-6c613bd6],\n.conrtlBox .right img[data-v-6c613bd6] {\n  width: 100%;\n  height: 100%;\n}\n.conrtlBox .right[data-v-6c613bd6] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.conrtlBox .left-child[data-v-6c613bd6],\n.conrtlBox .right-child[data-v-6c613bd6] {\n  width: 200px;\n  height: 100px;\n  text-align: center;\n}\n.conrtlBox .control_span[data-v-6c613bd6] {\n  width: 200px;\n  text-align: center;\n  line-height: 200px;\n  position: absolute;\n  color: #ffff;\n  font-size: 26px;\n}\n.conrtlBox .closed[data-v-6c613bd6] {\n  color: red;\n  margin-top: 200px;\n}\n.conrtlBox .open[data-v-6c613bd6] {\n  color: green;\n  margin-top: 200px;\n}\n.conrtlBox .form-btn[data-v-6c613bd6] {\n  text-align: right;\n}\n.statusActive[data-v-6c613bd6] {\n  color: red;\n}\n.status[data-v-6c613bd6] {\n  color: #70a436;\n}", ""]);
// Exports
module.exports = exports;
