<!--
 * @Author: jixuyu
 * @Date: 2021-09-20 19:47:27
 * @LastEditTime: 2022-08-17 17:35:00
 * @LastEditors: JIXUYU
 * @Description:
 * @FilePath: \front\src\views\config\components\uploadConfig.vue
 * 记得注释
-->
<template>
  <div>
    <el-form>
      <el-form-item :label="$t('config.EditOptimizerSpecification')">
        <el-link href="/doc/modeB.pdf" target="_blank">
          <el-image style="width: 100px; cursor:pointer;" :src="require('@/assets/design/modeB.png')" />
        </el-link>
      </el-form-item>
      <el-form-item :label="$t('config.EditOptimizerTemplate')">
        <el-button type="primary" plain icon="el-icon-download" @click="download">{{$t('config.download')}}</el-button>
      </el-form-item>
      <el-form-item :label="$t('config.excelLabel')">
        <el-upload
          drag
          :action="upload()"
          :before-upload="beforeUpload"
          :on-success="uploadSuccess"
          :on-error="uploadError"
          accept=".xlsx">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">{{$t('config.tipsExcelUpload1')}}<em>{{$t('config.tipsExcelUpload2')}}</em></div>
          <div class="el-upload__tip" slot="tip">{{$t('config.tipsExcel')}}</div>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {downloadTemplate, uploadHoneybee} from "@/api/config";
import * as util from "@/utils";
export default {
  components: {},
  // 定义属性
  data() {
    return {};
  },
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    download(){
      const stationId = util.readSession("stationId");
      if (!stationId) return;
      downloadTemplate(stationId);
    },

    upload(){
      const stationId = util.readSession("stationId");
      if (!stationId) return;
      return uploadHoneybee(stationId)
    },

    beforeUpload(file){
      // console.log(`类型：${file.type},大小：${file.size}`)
      const types = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
      const isType = types.includes(file.type);
      if (!isType) {
        this.$message.error(this.$t("config.tipsExcelWrongFileTpye"));
        return false;
      }else if(file.size<1 || file.size/1024/1024>10){
        this.$message.error(this.$t("config.tipsExcelOverSize"));
        return false;
      }
    },

    uploadSuccess(resp, file, fileList){
      // console.log(resp);
      if (resp.code === 200) {
        this.$message.success(this.$t("config.saveSucess"));
        if(resp.data){
          this.$alert(`${this.$t("config.errorHoneeybeeMacId")}(${resp.data})`, this.$t('common.warning'), {
            confirmButtonText: this.$t("config.confirm"),
            type: "warning",
          });
        }else{
          this.$emit("nextStep", 4);
        }
      }else{
        this.$message({
          message: resp.msg,
          type: "error",
          duration: 8000
        });
      }
    },
    uploadError(err, file, fileList){
      // console.error(err.msg);
      this.$message.error(this.$t("request.TheBackEndInterfaceIsAbnormal"));
    }
  },
  created() {},
  mounted() {}
};
</script>

<style lang="scss" scoped></style>
