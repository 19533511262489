// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/login/langLogo.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/login/langLogowhite.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".el-lang-icon[data-v-3e14cf21] {\n  display: inline-block;\n  width: 24px;\n  height: 24px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 100% 100%;\n  background-size: cover;\n  position: relative;\n  top: 7px;\n  margin-left: 10px;\n}\n@media screen and (min-width: 1044px) {\n.el-lang-icon[data-v-3e14cf21] {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 100% 100%;\n    background-size: cover;\n}\n}\n.langLabel[data-v-3e14cf21] {\n  color: #a5a4a4;\n}\n@media screen and (min-width: 1044px) {\n.langLabel[data-v-3e14cf21] {\n    color: #fff !important;\n}\n}\n.el-lang-wrap[data-v-3e14cf21] {\n  cursor: pointer;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: baseline;\n      -ms-flex-align: baseline;\n          align-items: baseline;\n}", ""]);
// Exports
module.exports = exports;
