<!--
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-11-14 11:58:12
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-05-05 18:01:56
-->
<template>
 <div class="conrtlBox">
  <div v-if="!child"  class="conrtlChlid">
  <div class="left" @click="handleSet('closed')">
<!--   <img src="https://gne.gnetek.com/images/setting/control-off.png" alt="">-->
   <span class="control_span">{{$t('control.close')}}</span>
   <p class="closed">{{$t('control.notiClosed')}}</p>
  </div>
  <div class="right" @click="handleSet('open')">
<!--   <img src="https://gne.gnetek.com/images/setting/control-on.png" alt="">-->
   <span class="control_span">{{$t('control.open')}}</span>
   <p class="open">{{$t('control.notiOpen')}}</p>
  </div>
  </div>
  <div v-if="child" class="conrtlChlid">
  <div class="left-child" @click="handleSet('closed')">
   <el-button type="danger" round size="medium" style="margin-bottom:10px">{{$t('control.close')}}</el-button>
   <p class="closed">{{$t('control.notiClosed')}}</p>
  </div>
  <div class="right-child" @click="handleSet('open')">
   <el-button type="success" round size="medium" style="margin-bottom:10px">{{$t('control.open')}}</el-button>
   <p class="open">{{$t('control.notiOpen')}}</p>
  </div>
  </div>
  <el-dialog :title="dialog.title" :visible.sync="dialog.visible" width='25rem'>
   <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="box_from">
    <el-form-item :label="$t('control.ptcodeR')" prop="resertCode">
     <el-input v-model="ruleForm.resertCode" :placeholder="$t('control.PEnter')"></el-input>
    </el-form-item>
    <el-form-item :label="$t('control.ptcode')">
     <el-input v-model="imgSrc" disabled>
         <template slot="append">
               <el-button @click="getVerify">{{$t('control.angin')}}</el-button>
         </template>
      </el-input>
     </el-form-item>
   <el-form-item class="form-btn">
    <el-button @click="dialog.visible = false" type="primary" round>{{$t('control.cancel')}}</el-button>
    <el-button @click="submitForm('ruleForm')" type="success" round>{{$t('control.confirm')}}</el-button>
   </el-form-item>
   </el-form>
  </el-dialog>

   </div>
</template>

<script>
 import {
  captcha,
  verify,
  cmdControl
 } from "@/api/register";
 /**
  * @author: JIXUYU
  * @since: 2021-11-14
  * @router('/conrtlBox')
  */
 export default {
  name: "conrtlBox",
  components: {},
  props: {
    idList:{
     type:String,
     default:''
    },
    child:{
     type:Boolean,
     default:false
    }
  },
  data() {
   return {
    dialog: {
     title: '',
     visible: false
    },
    ruleForm: {
     resertCode: ''
    },
    status:'',
    imgSrc: '',
    rules: {
     resertCode: [{
       required: true,
       message: this.$t('control.PenterCode'),
       trigger: "blur"
      },
      {
       validator: this.validateCode,
       trigger: ["blur"]
      },
     ],
    }
   }
  },
   watch: {
    "dialog.visible": {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
         this.$refs["ruleForm"] && this.$refs["ruleForm"].resetFields();
        }

      },
    },
  },

  methods: {
      /**
    * @description:
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */
   submitForm(formName){
       let _this = this;
       this.$refs[formName].validate(async(valid) => {
        if (valid) {
         const params = {
           ids:_this.idList,
           status:this.status=='open'?'0':'1'
         }
         await cmdControl(params).then((res) => {
          if (res.code === 200 ) {
           _this.$message.success(this.$t('control.sucess'));
           this.dialog.visible=false
          }
         });
        } else {
         return false;
        }
       });
   },
   /**
    * @description:
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */
   handleSet(val) {
    if(this.idList=='FFFFFFFF'||this.idList!='')
    {
    this.dialog.visible = true
    this.status=val
    if(val=='open'){
     this.dialog.title=this.$t('control.openStation')
    }else{
     this.dialog.title=this.$t('control.closeStation')
    }
    }else{
    this.$message.success(this.$t('control.pCheckout'))
    }



   },
   /**
    * @description: 校验验证码
    * @param {*} rule
    * @param {*} value
    * @param {*} callback
    * @return {*}
    * @author: JIXUYU
    */
   async validateCode(rule, value, callback) {
    const {
     code,
     data
    } = await verify({
     code: this.ruleForm.resertCode,
     businessCode: 'stationControl'
    })
    if (code != 200) {
     callback(this.$t('stationReport.errorCode'))
    } else {
     callback();
    }
   },
   /**
    * @description: 获取验证码
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */
   getVerify() {
    let params = {
     businessCode: 'stationControl',
     captchaType: 0,
     resultType: 1
    }
    captcha(params).then((res) => {
     if (res.data.code == 200) {
      this.imgSrc = res.data.data
     }
    })
   },
  },

  mounted() {this.getVerify()}
 }
</script>

<style lang="scss" scoped>
 @import '../style/index';
</style>
