/*
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-14 18:14:11
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-24 15:32:54
 */
module.exports = {

  common: {
    warning: '警告',
    error: '错误',
    hint: '提示',
    input: '输入'
  },

 login: {
  title: '欢迎使用GNE光伏监控系统',
  login: '登录',
  demo: '演示',
  register: '注册账号',
  Forgot: '忘记密码',
  // text: '欢迎使用GNE网页版光伏监控系统！您可以扫描左侧二维码安装安卓版APP，通过手机查看您电站的实时状态。',
  text: '您可以在Google play或者Apple store直接搜索"gne"，或者通过下方连接下载手机客户端:',
  appUrl:'安装包下载',
  googleAppUrl:'谷歌商店',
  appleAppUrl:'苹果商店',
  sure: '您确定要退出监控平台吗?',
  person: '个人中心',
  station: "电站列表",
  out: '退出登录',
  tip: '提示',
  confirm: '确定',
  cancel: '取消',
  beSure: '是否确认删除',
  china: '中文',
  english: '英文',
  janpan: '日文',
  sucess:'切换多语言成功',
  switchLang:'语言',
  technicalSupport:'技术支持',
  wechat:'微信',
  whatsApp:'WhatsApp',
  monitoringPlatformUsageInstructions: '监控平台使用说明'
 },
 register: {
  title: '江苏集能易新能源技术有限公司',
  registerAccount: '注册账号',
  userName: '用户名',
  email: '邮箱',
  ConfirmEmail: '确认邮箱',
  password: '密码',
  ConfirmPassword: '确认密码',
  company: '公司',
  postcode: '邮编',
  surname: '姓',
  timezone: '时区',
  Verification: '验证码认证',
  street:'街道',
  regain: '重新获取',
  back: '返回登录',
  register:'注册',
  setName: '设置用户名',
  pSetName: '请设置用户名',
  setEmail: '请输入邮箱',
  pSetEmail: '请输入注册邮箱',
  cSetEmail: '再次确认邮箱',
  PCsetEmail:'请确认注册邮箱',
  setPassword: '设置登录密码',
  PSetPassword: '请设置登录密码',
  CPasword: '确认登录密码',
  PcPassword: '请确认登录密码',
  PCompany: '请输入公司名称',
  PEnter: '请输入',
  PSelect: '请选择',
  PenterCode: '请输入验证码',
  setCode: '发送验证码' ,
  resert: '重置成功',
  chanName: '名',
  naromlRole: '角色',
  Prole: '请选择角色',
  sucess: '操作成功',
  pCheckout: '请先勾选一条数据',
  resSucess:'注册成功',
  max16:'最多输入16个字符',
  moveVerify:'请进行滑块验证',
  getCaptcha:'获取验证码',
  sentCaptcha2Email:'验证码已发送至邮箱',
  sendCaptchaAgain:'秒后，可重新发送验证码',
  usernameIsExist:'用户名已存在',
  emailIsExist:'邮箱号已存在',
 },
 //首页
 dashbord: {
  dateAmount: '日电量示意图',
  monthAmount: '月电量示意图',
  weekAmount:'周电量示意图',
  yearAmount: '年电量示意图',
  Charge: '电量对比图',
  Power: '功率曲线图',
  month: '月',
  quarter:'季',
  year: '年',
  Daily: '日发电量',
  Total: '总收入',
  NumberS: '组件数量',
  capacity: '装机容量',
  gross: '总发电量',
  health: '健康度',
  weather: '天气',
  Device: '设备名称',
  Alarm: '报警内容',
  time: '时间',
  serial:'序号',
  max3Diff:'最多选择3个年份比对',
  currentPower: '当前功率'
 },
 //曲线
 curve: {
  choiceDate: '选择日期',
  power: '功率',
  electricity: '电量',
  Pfliter: '输入关键字进行过滤',
  PSelect: '勾选比对内容不能大于7个',
  Pset:'请先选择优化器'
 },

 // 设备报警
 device: {
  to: '至',
  startTime: '开始日期',
  endTime: '结束日期',
  search: '搜索',
  Unhandled: '未处理警报',
  Historical: '历史警报',
  name: '名称',
  place: '地点',
  time: '时间',
  type:'类型',
  deadline:'截至时间',
  detail:'警告详情'
 },
 //电站列表
 stationReport: {
  pleaseSelectStation: '请选择电站',
  stationList: '电站列表',
  addList: '安装列表',
  country: '国家',
  district: '区',
  city:'市',
  stationName:'电站名称',
  addStation:'新增电站',
  search:'搜索',
  reset: '重置',
  Verification:'验证码认证',
  Penter:'请输入',
  code:'验证码',
  regain:'重新获取',
  cancel:'取消',
  confirm:'确定',
  PVerification:'请输入验证码',
  OperationSuccess:'操作成功',
  errorCode:'验证码输入错误，请重新输入',
  state:'电站状态',
  name:'名称',
  address:'地址',
  cumulative: '累计电量',
  peakPower:'装机容量',
  installation:'安装时间',
  operation:'操作',
  open:'开启',
  down:'关闭',
  openStation:'开启优化器/关断器',
  closeStation:'关闭优化器/关断器',
  Switch: '切换选中的电站?',
  Continue: '继续安装',
  delete: '删除',
  isDelete:'是否确定删除',
  SearchPowerStation: '搜索电站',
   PowerSwitch: '电站切换',
   HasBeenInstalled: '已安装',
   NotInstalled: '未安装',
 },
 //电站控制
 control: {
  MainSwitch: '总开关',
  SeparateSwitch: '分开关',
  close: '关闭',
  open:'开启',
  closeStation: '关闭优化器/关断器',
  openStation: '开启优化器/关断器',
  ptcodeR: '验证码认证',
  PEnter: '请输入',
  ptcode: '验证码',
  angin: '重新获取',
  cancel:'取消',
  confirm:'确定',
  notiClosed: '请注意，点击按钮后，设备将会停止发电！',
  notiOpen:'请注意，点击按钮后，设备将会开启发电！',
  SummaryId: '汇总器MACID',
  PositionCode: '位置编号',
  status: '设备状态',
  updateTime: '更新时间',
  selectAll: '全选',
  PenterCode: '请输入验证码',
  sucess: '操作成功',
  pCheckout:'请先勾选一条数据',

 },
 //设备状态
 status: {
  SummaryId: '汇总器MACID',
  version: '版本号',
  updateTime: '更新时间',
  status: '设备状态',
  PositionCode: '位置编号',
  voltage: '电压(V)',
  current: '电流(A)',
  power:'功率(W)',
  warnCode: '状态',

 },
 // 角色管理
 roleManagement: {
  creatRole: '创建角色',
  detete:'删除',
  Penter: '请输入角色名称',
  roleName: '角色名称',
  roleNotice: '角色说明',
  action: '操作',
  edit: '编辑',
  cancel:'取消',
  submit: '提交',
  addRole: '新增角色',
  editRole: '编辑角色',
  selectAll: '全选',
  PSelect: '请先勾选删除项',
  Resource:'资源分配',
   TheUploadedFileFormatIsIncorrect:'上传文件格式不正确',


 },
  // 用户管理
  userManagement: {
   creatUser: '创建用户',
   detete:'删除',
   Penter: '请输入用户名称',
   userName: '用户名称',
   userStatus: '用户状态',
   action: '操作',
   edit: '编辑',
   cancel:'取消',
   submit: '提交',
   addUser: '新增用户',
   editUser: '编辑用户',
   selectAll: '全选',
   PSelect: '请先勾选删除项',
   Email:'邮箱',
    reset:'重置密码',
    ModifySuccessfully:'修改成功',
 },
 //个人中心
 PersonalMsg: {
  userName: '用户名',
  postcode: '电话',
  Certified: '邮箱',
  reset:'重置密码',
  save: '保存',
  initalPasw: '密码',
  Pinit: '请输入初始密码',
  NewPasw: '新密码',
  PnewPasw: '请输入新密码',
  CPasw: '确认新密码',
  PCword: '请再次输入新密码',
  cancel:'取消',
  confirm: '确定',




 },
 //电站设置
 config: {
  plant: "电站信息",
  Inverter: "逆变器组件",
  Summary: "汇总器",
  setOptimizer: "设置优化器",
  Layout: "布局设置",
  PnewPower: "请新建电站信息",
  Pcomplete: "请按照步骤填写完整",

  manufacturer: "组件制造商:",
  modelType: "型号:",
  comPower: "组件功率（w）:",
  Tags: "标签：",
  InverterMan: "逆变器制造商：",
  RatedPower: "额定功率（kw）：",



  SummarySet: "汇总设置",
  UnitId: '请输入Mac ID',
  PCode: '长度超过8个字符',
  notExist: "Mac ID不存在",
  save: '保存',
  undo: '撤销',
  redo: '恢复',
  PenterAgin: "该Mac ID不符合要求，请重新输入",
  UnitEror: "Mac ID有误，请检查Mac ID是否正确",

  Pselect: "请选择输入类型",
  PMAc: '请输入MAC ID',
  MacNotExist: "MAC Id不存在",
  Components: "组件",
  PComponents: '请选择组件',
  delete: '删除',
  insert: '插入',
  Single: "单输入",
  Double: "双输入",


  Method1: "方式1",
  Method2: "方式2",
  string: "组串",
   Inprocessing:"上传优化器",


   inverterName: "逆变器-",
   moduleName: "组件-",


  Xcoordinate: "x坐标",
  Ycoordinate: "y坐标",
  success: "新增成功",



  stationName: "电站名称：",
  Countries: "国家：",
  ProjectNo: "项目编号：",
  Provinces: "省份：",
  Company: "公司：",
  City: "市（县）：",
  chargePerson: "电站负责人：",
  Area: "地区：",
  Telephone: "电话：",
  Street: "街道：",
  Time: "时区：",
  PselectEnter: "请选择",
  Price: "电价：",
  PriceCode: "请选择货币",
  pictures: "上传电站图片：",
  Upload: '点击上传',
  tips: '请上传图片格式文件，图片大小不能超过1MB',
  tipsOverSize:'上传图片大小不能超过 1MB!',
  tipsWrongFileTpye:'上传图片只能是 JPG、JPEG、PNG 格式!',
  tipsMaximumUploads:'最多上传1张图片哦！',

  excelLabel:"上传excel文件：",
  tipsExcelUpload1:"将文件拖到此处，或",
  tipsExcelUpload2:"点击上传",
  tipsExcel:"只能上传excel(.xlsx)文件，且不超过2M",
  tipsExcelOverSize:"上传文件大小不能超过 10MB!",
  tipsExcelWrongFileTpye:'只能上传excel(.xlsx)文件!',
  tipsExcelMaximumUploads:'最多上传1个文件哦！',


  beSave: "是否保存当前内容！",
  confirm: '确定',
  cancel: "取消",
  NewOptimizer: "新增优化器",

  beDeleteInverterOrMoudel: "您删除了逆变器或组件，确定要保存删除操作吗？",
  beDeleteBeehive: "确定要删除操作汇总器吗？",

  MacError: 'Mac地址有误，请检查Mac是否正确',
  saveSucess: '保存成功',
  Pwrite: '请填写完整',
  beSaveString: "是否保存当前内容！",
  beDetelString: "是否删除当前组串！",
  paswordRule: "密码应是6-12位数字、字母或字符！",
  resetMacId: "该macId不符合要求，请重新输入",
  beDetelIntervl: "是否删除当前优化器！",
  duplicateMacId: "重复MACID",
  saveToNext: "是否完成优化器设置，需要进下一步？",

  EditOptimizerSpecification: "编辑优化器的Excel说明书:",
  EditOptimizerTemplate: "编辑优化器的Excel模板：",
  download: "下载",

  doDeleteUserStations: "您确定要删除用户所有的电站吗？",


  PMacIdImage:"请参考图片输入Mac ID",
  clickMe:"点我",
  inverterTips:"同一型号的逆变器只需要填一个",
  moduleTips:"同一型号的组件只需要填一个",
  testText: "测试文字",
  powergrid: "电网",
  compass: "指南针",
  swarm: "采集器",
  errorHoneeybeeMacId:"这些优化器MAC地址不正确或者已在其它电站中使用。",
  honeybeeNumOverMaximum:"分组的优化器数量超过了25台，确定要保存吗？",
 },
 //布局设计
 design: {
  voltage: '电压',
  current: '电流',
  power:'功率',
  temperature: '温度',
  warnCode: '状态',
  PSelect: '请选择',
  physical:"物理",
  logic:"逻辑",

   power_simple: '功率',
   voltage_simple: '电压',
   current_simple: '电流',
   temperature_simple: '温度',
   start: '开始',
   stop: '暂停',

   defaultColor: '默认',
   themeColor: '主题',
   standardColor: '标准',
   moreColors: '更多',

   selectDate: '请选择日期'

 },

 addRules: {
  Powassd: '请输入密码',
  nextCode: '密码不多于16个字符',
  nextNum: '密码为字母、数字、字符的两种组合',
  PowassdAgen: '请再次输入密码',
  NoSure: '两次输入密码不一致!',
  NoEmpty: '内容不能为空',
  NumSet: '内容长度不得超过64字符',
  NumSix: '内容长度不得超过16字符',
  Behuande: '内容必须为0到100的整数值',
  Bephone: '请填写正确手机号',
  BeEmail: '请填写正确邮箱',
  BeSure: '请输入正确内容',
  Numthree: '长度不能超过32个字符',
  setInput: '请输入内容',
  Prole: '请选择角色类型',
  entSix: '密码不少于6个字符',
  setLogo: '修改成功,请重新登录!',
  setCodeNum: '6~16位字母,数字,或者符号组合',
   EmailFormat: '请输入正确的邮箱格式',
   Max8Char: '长度超过8个字符',
   Max32Char: '长度超过32个字符',
   Max64Char: '长度超过64个字符',
   PleaseEnterYourEmailAddressFirst:'请先输入邮箱地址'


 },
 otherMain: {
  // uplod:'上传优化器'
  uplod:'开发中！',
   JustNow:'刚刚',
   MinutesAgo:'分钟前',
   HoursBefore:'小时前',
   OneDayBefore:'1天前',
   Month:'月',
   Day:'日',
   Hour:'时',
   Minute:'分',

 },
 request:{
   VerificationFailedPleaseLogInAgain:'验证失败，请重新输入',
   TheInputContentCannotBeEmpty:'输入内容不能为空',
   PleaseEnterYourEmailAddress:'请输入邮箱地址',
   NetworkExceptionPleaseTryAgainLater:'网络异常，请稍后重试',
   RequestExceptionInterception:'请求异常拦截',
   TheBackEndInterfaceIsAbnormal:'后端接口未知异常',
    TheBackEndInterface:'后端接口',
   Abnormal:'异常',
 },
 validate: {
   TheInputContentCannotBeEmpty:'输入内容不能为空',
   PleaseEnterThePlantName:'请输入电站名称',
   PleaseEnterCountry:'请输入国家',
   PleaseEnterRegion:'请输入地区',
   PLeaseEnterProvince:'请输入省份',
   PleaseEnterTheCity:'请输入市',
   PleaseEnterStreet:'请输入街道',
   PleaseEnterANumber:'请输入数字',
    PleaseEnterA5Number:'请输入大于0的数字',
    PleaseEnterManufacturer:'请输入组件生产商',
    PleaseEnter200to1000Number:'组件功率应该在200~1000之间',

 },

 rules:{
   TheContentCannotBeEmpty:'内容不能为空',
   TheValueContainsAMaximunOf64Characters:'内容长度不得超过64字符',
   TheContentCannotExceed20Characters:'内容不得超过20字符',
   TheContentMustBeNumeric:'内容必须为数字值',
   TheContentMustBeLettersAndNumbers:'内容必须为字母和数字',
   ContentMustBeAnIntegerValueFrom0To100:'内容必须为0到100的整数值',
   PleaseFillInTheCorrectCellPhoneNumber:'请填写正确手机号',
   PleaseFillInTheCorrectEmailAddress:'请填写正确邮箱',
   ContainsMoreThan64Characters:'长度超过64个字符',
   PleaseEnterTheCorrectFormat:'请输入正确格式',
   TheValueContainsMoreThan32Characters:'长度超过32个字符',
   PleaseEnterContent:'请输入内容',
   PleaseSelectCompany:'请选择公司',
   PleaseSelectARoleType:'请选择角色类型',
   TheIdCanOnlyBeANumber:'ID只能为数字',
   PleaseEnterTheLabelName:'请输入标签名称',
   ContainsMoreThan16Characters:'长度超过16个字符',
   PleaseSelectA:'请选择',
   PleaseEnterAModelName:'请输入模型名称',
   PleaseEnterTheVersionNumber:'请输入版本号',
   PleaseEnterTheModelDescription:'请输入模型描述',
   PleaseSelectTheTrainingEnvironment:'请选择训练环境',
   PleaseEnterTheAlgorithmPath:'请输入算法路径',
   PleaseSelectTheTrainingSet:'请选择训练集',
   PleaseSelectReviewers:'请选择评审人',
   PleaseSelectAPlan:'请选择方案',
   PleaseInputYourReviewComments:'请输入审核意见',
   PleaseEnterASchemaName:'请输入方案名称',
   ThePasswordIsACombinationOfLettersDigitsAndCharacters:'密码为字母、数字、字符的两种组合',
PleaseEnterTheCorrectName:'请输入正确名称',
PleaseEnterCorrectInstructions:'请输入正确说明',
EnterTheCorrectPath:'请输入正确路径',
TheValueContainsMoreThan128Characters:'长度超过128个字符',
PleaseEnterTheCorrectContent:'请输入正确内容',
PleaseSelectAtLeastOne:'请至少选择一个',
    TheValueContainsAMaximumOf256Characters:'长度不超过256个字符',

 }






}
